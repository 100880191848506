import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../config";
import { AUTH_TOKEN_KEY } from "../config";
import { getAuthToken } from "../utils/tokenprovider";


const SWAPS_URL = `${BASE_URL}/api/v1/dashboard/getswapstoday`
const REVENUE_URL =`${BASE_URL}/api/v1/dashboard/getcabinetrevenue`
const CABINETSURL = `${BASE_URL}/api/v1/dashboard/cabinets`


const initialState = {
    cabinetdata:{
        data: null,
        loading: false,
        error:""
    },
    swaps:{
        data: null,
        loading: false,
        error:""
    },
    revenue:{
        data: null,
        loading: false,
        error:""
    }
}

export const fetchTodaySwaps = createAsyncThunk("swaps/swapsdata", async(_, thunkApi)=>{

    const token = getAuthToken(AUTH_TOKEN_KEY)
    if(!token) throw new Error("Auth token not found")

    try {
        const res = await fetch(SWAPS_URL, {
            headers: {
                "authorization":`Bearer ${token}`
            }
        })
        if(res.status === 200){
            const data = await res.json()
            return data?.swapstoday // THIS IS AN ARRAY
        }else {
            throw new Error("ERROR WHILE FETCHING CABINET MAP DATA:::", res)
        }
    } catch (error) {
        console.log("UNABLE TO FETCH CABINET SWAPS INFO::", error)
        return thunkApi.rejectWithValue("Error loading swaps data")
    }
})


export const fetchRevenueToday = createAsyncThunk("revenue/revenueData", async(_, thunkApi)=>{


    const token = getAuthToken(AUTH_TOKEN_KEY)
    if(!token) throw new Error("Auth token not found")

    try {
        const res = await fetch(REVENUE_URL, {
            headers:{
                "authorization":`Bearer ${token}`
            }
        })
        if(res.status === 200){
            const data = await res.json()
            return data?.data // THIS IS AN ARRAY
        }else {
            throw new Error("ERROR WHILE FETCHING REVENUE DdispatchATA:::", res)
        }
    } catch (error) {
        console.log("UNABLE TO FETCH REVENUE DATA::", error)
        return thunkApi.rejectWithValue("Error loading revenue data")
    }
})

export const fetchCabinets = createAsyncThunk("cabinets/fetchCabinets", async(_, thunkApi)=>{
    const token = getAuthToken(AUTH_TOKEN_KEY)
    if(!token) throw new Error("Auth token not found")
    try {
        const res = await fetch(CABINETSURL, {
            headers:{
                "authorization":`Bearer ${token}`
            }
        })
        if(res.status === 200){
            const data = await res.json()
            return data?.data // THIS IS AN ARRAY
        }else {
            throw new Error("ERROR WHILE FETCHING CABINET DATA:::", res)
        }
    } catch (error) {
        console.log("UNABLE TO FETCH CABINET DATA::", error)
        return thunkApi.rejectWithValue("Error loading cabinet data")
    }
})

const cabinetDataSlice = createSlice({
    name:"CabinetData",
    initialState,
    reducers:{},
    extraReducers :(builder)=>{
        builder
            .addCase(fetchTodaySwaps.pending, (state)=>{
                state.swaps.error = ""
                state.swaps.loading = true
            })
            .addCase(fetchTodaySwaps.fulfilled, (state, action)=>{
                state.swaps.error = ""
                state.swaps.data = {
                    raw: action.payload,
                    swapsCompleted: action.payload?.length
                }
                state.swaps.loading = false
            })
            .addCase(fetchTodaySwaps.rejected, (state, action)=>{
                state.swaps.error = action.payload
                state.swaps.loading = false
            })
            .addCase(fetchRevenueToday.pending, (state)=>{
                state.revenue.error = ""
                state.revenue.loading = true
            })
            .addCase(fetchRevenueToday.fulfilled, (state, action)=>{
                state.revenue.error = ""
                state.revenue.data = {
                    raw: action.payload,
                    paymentToday: action.payload.reduce((accumulator, currentvalue) => accumulator + Number(currentvalue.amount), 0)

                }
                state.revenue.loading = false
            })
            .addCase(fetchRevenueToday.rejected, (state, action)=>{
                state.revenue.error = action.payload
                state.revenue.loading = false
            })
            .addCase(fetchCabinets.pending, (state)=>{
                state.cabinetdata.error = ""
                state.cabinetdata.loading = true
            })
            .addCase(fetchCabinets.fulfilled, (state, action)=>{
                state.cabinetdata.error = ""
                state.cabinetdata.data = {
                    raw: action.payload,
                    energyUsedToday: action.payload.reduce((accumulator, currentvalue) => {
                        const energy = parseFloat(currentvalue.energyUsed);
                        return accumulator + (isNaN(energy) ? 0 : energy);
                    }, 0)
                    
                }
                state.cabinetdata.loading = false
            })
            .addCase(fetchCabinets.rejected, (state, action)=>{
                state.cabinetdata.error = action.payload
                state.cabinetdata.loading = false
            })
    }
})

export default cabinetDataSlice.reducer
