import React from 'react'
import { Box, Typography, useTheme, CircularProgress } from "@mui/material"
import { tokens } from "../../../../theme"
import { useGetLastDispatchedQuery } from '../../../../state_management/batteries'
import { formatDateString } from '../../../../utils/dateUtils'

const GetlastDispatched = ({batterypoolid}) => {
  
  const theme = useTheme() 
  const colors = tokens(theme.palette.mode)

  const {data, isLoading, error} = useGetLastDispatchedQuery({
    batteryid:batterypoolid

  })

  return (
  <Box gap={"5px"} display={"flex"} justifyContent={"center"} alignItems={"center"} sx={{
    padding:"10px 13px",
    width:"100%",
    background: error && colors.grey[500]

  }}>
      {isLoading && <CircularProgress />}
      {data &&(
              <Box >
                  <Typography>Last dispatched on {formatDateString(data?.data?.createdAt)}</Typography>
                  {data?.cabinet?.display_name && (<Typography>From {data?.cabinet?.display_name}</Typography>)}
              </Box>
            )}
  </Box>
)
}

export default GetlastDispatched