import { Box } from "@mui/material";
import DaySwapChart from "./charts/day/DaySwapChart";
import HourSwapChart from "./charts/hour/HourSwapChart";
import RollingSwapChart from "./charts/rolling/RollingSwapChart";

const SwapCharts = () => {
	return (
		<Box
			display={"grid"}
			gridTemplateColumns={"repeat(12, 1fr)"}
			gridAutoRows="140px"
			gap="20px"
			mt={"20px"}
			sx={{
				marginTop: "20px",
				marginBottom: "40px",
			}}
		>
			<Box
				gridColumn="span 6"
				gridRow="span 3"
			>
				<HourSwapChart />
			</Box>
			<Box
				gridColumn="span 6"
				gridRow="span 3"
			>
				<DaySwapChart />
			</Box>
			<Box
				gridColumn="span 12"
				gridRow="span 3"
			>
				<RollingSwapChart />
			</Box>
		</Box>
	);
};

export default SwapCharts;
