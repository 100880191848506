import React from "react";
import { Box, useTheme, Typography, CircularProgress } from "@mui/material";
import { tokens } from "../../../theme";
import SwapsChart from "../../../components/cabinetCharts/SwapsCompleted";

const SwapsDisplay = ({ data, swapscount }) => {

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	if (data?.loading || !data?.data) {
		return (
			<>
				<Box mt="25px" p="0 30px">
					<Box>
						<Typography
							variant="h3"
							fontWeight="bold"
							color={colors.greenAccent[500]}
						>
							Loading
						</Typography>
					</Box>
				</Box>
				<Box
					height="250px"
					m="-20px 0 0 0"
					display={"flex"}
					justifyContent={"center"}
					alignItems={"center"}
				>
					<CircularProgress />
				</Box>
			</>
		);
	}
	return (
		<>
			<Box
				mt="25px"
				p="0 30px"
				display="flex "
				justifyContent="space-between"
				alignItems="center"
			>
				<Box>
					<Typography
						variant="h5"
						fontWeight="600"
						color={colors.grey[100]}
					>
						Swaps
					</Typography>
					<Typography
						variant="h3"
						fontWeight="bold"
						mt={"10px"}
						color={colors.greenAccent[500]}
					>
						{swapscount} swaps today
					</Typography>
				</Box>
			</Box>
			<Box height="380px" m="-20px 0 0 0">
				<SwapsChart data={data?.data?.raw} />
			</Box>
		</>
	);
};

export default SwapsDisplay;
