import { useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import { AgCharts } from "ag-charts-react";

const Chart = ({ isCustomLineColors = false, data }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	function filterDataByCurrentHour(data) {
		const currentHour = new Date().toLocaleTimeString("en-US", {
			hour12: false,
			hour: "2-digit",
		});
		return data.map((item) => {
			return item.hour <= currentHour
				? item
				: {
						hour: item.hour,
						monthly_average_swaps: item.monthly_average_swaps,
				  };
		});
	}

	const filtered = filterDataByCurrentHour(data);
	const transformedData = filtered?.map((entry) => {
		return {
			hour: `${entry.hour.substring(0, 2)}`,
			swaps: entry.current_day_swaps
				? parseFloat(entry.current_day_swaps)
				: null,
			avgswaps: parseFloat(entry.monthly_average_swaps),
		};
	});

	return (
		<AgCharts
			options={{
				series: [
					{
						xKey: "hour",
						yKey: "avgswaps",
						yName: "Average",
            stroke: colors.greenAccent[200],
            strokeWidth: 3,
            marker: {
              shape: 'circle',
              size: 8,
              fill: colors.greenAccent[500], 
              stroke: colors.greenAccent[200],
              strokeWidth: 2,
            },
            interpolation: { type: "smooth" },
					},
					{
						xKey: "hour",
						yKey: "swaps",
						connectMissingData: false,
						yName: "Current",
            stroke: colors.blueAccent[200],
            strokeWidth: 3,
            marker: {
              shape: 'cross',
              size: 8,
              fill: colors.blueAccent[500], 
              stroke: colors.blueAccent[200],
              strokeWidth: 2,
            },
            interpolation: { type: "smooth" },
					},
				],
				data: transformedData,
				title: {
					text: "Swaps by the hour",
          color: colors.grey[100],
          fontSize: 25,
          fontWeight:"bold"
				},
				subtitle: {
					text: "Swaps compared with last month's average",
          color: colors.greenAccent[500],
          fontSize: 15,
				},
        height:450,
        background: {
          fill: colors.primary[400], 
        },
        axes:[
          {
            type:"category",
            position:"bottom",
            label:{
              rotation:0,
              color: colors.grey[100]
            },
            title:{
              enabled:true,
              text:"Hours of the day",
              color:colors.grey[100]
            }
          },
          {
            type:"number",
            position:"left",
            label:{
              rotation:0,
              color: colors.grey[100]
            },
            title:{
              enabled:true,
              text:"Number of Swaps",
              color:colors.grey[100]
            }
          }
        ],
        legend:{
          item:{
            label:{
              color:colors.grey[100],
            }
          }
        }
			}}
		/>
	);
};

export default Chart;
