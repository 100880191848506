import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from '../config'
import { getAuthToken } from "../utils/tokenprovider";

export const PaymentsApi = createApi({
    reducerPath:'paymentsApi', 
    baseQuery: fetchBaseQuery({
        baseUrl:BASE_URL,
        prepareHeaders :(headers) =>{
            const token = getAuthToken()

            if(token){
                headers.set("authorization", `Bearer ${token}`)
            }

            return headers
        }
    }),
    endpoints:(builder)=>({
        getCustomerPayment : builder.query({
            query: ({customerid, startdate, enddate})=>`${BASE_URL}/api/v1/payments?customerid=${customerid}&startdate=${encodeURIComponent(startdate)}&enddate=${encodeURIComponent(enddate)}`, 
            transformResponse: (response) => response?.PaymentsLog
        })
    })
})

export const {useGetCustomerPaymentQuery} = PaymentsApi