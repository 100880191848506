import { RotatingSquare } from "react-loader-spinner"

function SquareSpinner({color, width, height}) {
  return (
    <RotatingSquare
        visible={true}
        height={height}
        width={width}
        color= {color}
        ariaLabel="rotating-square-loading"
        wrapperStyle={{}}
        wrapperClass=""
    />
  )
}

export default SquareSpinner