import { Search } from "@mui/icons-material";
import {
    Box,
    IconButton,
    InputAdornment,
    TextField,
    useTheme
} from "@mui/material";
import {
    GridToolbarColumnsButton,
    GridToolbarDensitySelector,
    GridToolbarExport
} from "@mui/x-data-grid";
import React from "react";
import { tokens } from "../../../../theme";

const BatteryGridToolbar = ({batpoolsearchdisplay, setBatPoolSearchDisplay, setBattpoolid}) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)
	return (
    <Box>
        <Box sx={{
            display:"flex",
            justifyContent:"space-between"
        }}>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} sx={{
                background: colors.blueAccent[700],
                marginBottom:"-7px",
                borderRadius: "5px",
                paddingInline:"10px"
            }} >
                <GridToolbarColumnsButton/>
                <GridToolbarDensitySelector />
                <GridToolbarExport />
            </Box>
            <TextField 
                label="Search Batterypool id"
                sx={{mb:"0.5rem", width:"15rem"}}
                onChange={(e)=>setBatPoolSearchDisplay(e.target.value)}
                value={batpoolsearchdisplay}                
                variant="standard"
                InputProps={{
                    endAdornment:(
                        <InputAdornment>
                            <IconButton onClick={()=>{
                                setBatPoolSearchDisplay("")
                                setBattpoolid(batpoolsearchdisplay)
                            }}>
                                <Search  />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </Box>
    </Box>
    )
};

export default BatteryGridToolbar;
