import React from 'react'
import { useGetRollingSwapChartQuery } from '../../../../../state_management/swapapi'
import Chart from "./Chart"
import { Box, Typography, useTheme, CircularProgress } from '@mui/material'
import { tokens } from '../../../../../theme'

const RollingSwapChart = () => {
  const {data, isLoading} = useGetRollingSwapChartQuery()
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

  if (isLoading || !data) {
		return (
			<>
				<Box mt="25px" p="0 30px">
					<Box>
						<Typography
							variant="h3"
							fontWeight="bold"
							color={colors.greenAccent[500]}
						>
							Loading
						</Typography>
					</Box>
				</Box>
				<Box
					height="250px"
					m="-20px 0 0 0"
					display={"flex"}
					justifyContent={"center"}
					alignItems={"center"}
				>
					<CircularProgress />
				</Box>
			</>
		);
	}
	return (
		<>
			<Box
				mt="25px"
				p="0 30px"
				display="flex "
				justifyContent="space-between"
				alignItems="center"
			>
				<Box>
					<Typography
						variant="h3"
						fontWeight="bold"
						mt={"10px"}
						color={colors.greenAccent[500]}
					>
						{`Swaps (rolling 30 days)`}
					</Typography>
				</Box>
			</Box>
			<Box height="380px" m="-20px 0 0 0">

        {data && <Chart data={data?.data}/>}
			</Box>
		</>
	);
}

export default RollingSwapChart