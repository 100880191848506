import { Box, useTheme, Typography } from "@mui/material"
import { tokens } from "../../../../theme"
import { KeyboardDoubleArrowRight, KeyboardDoubleArrowLeft } from "@mui/icons-material"


const BatterySwapState = ({id, row}) => {
    const theme = useTheme() 
    const colors = tokens(theme.palette.mode)

    const checkstatus = ()=>{
        if(id.toLowerCase() === row?.deposited_battery_id?.toLowerCase()){
            return "deposited"
        }

        return "dispatched"
    }

  return (
    <Box 
        sx={{
            display:"flex", 
            flexDirection:"row",
            justifyContent:"space-between",
            alignItems:"center", 
            backgroundColor:checkstatus() === "deposited" ? colors.blueAccent[900] : colors.blueAccent[700], 
            padding:"10px",
        }}
    >   
        
        {checkstatus() === "deposited"
            ? <KeyboardDoubleArrowRight />
            : <KeyboardDoubleArrowLeft />
        }
        {checkstatus() === "deposited"
            ? <Typography>Deposited</Typography>
            : <Typography>Dispatched</Typography>
        }
        
    </Box>
  )
}

export default BatterySwapState