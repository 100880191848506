import { Box, useTheme, Typography, CircularProgress } from "@mui/material"
import { tokens } from "../../../../theme"
import { useGetlastDepositedQuery } from "../../../../state_management/batteries"
import { formatDateString } from "../../../../utils/dateUtils"

const GetlastDeposited = ({batterypoolid}) => {
  
    const theme = useTheme() 
    const colors = tokens(theme.palette.mode)
    const {data, isLoading, error} = useGetlastDepositedQuery({
        batteryid:batterypoolid
    })

    return (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} sx={{
            padding:"10px 13px",
            width:"100%",
            background: error && colors.grey[500]
          }}>
            {isLoading && <CircularProgress />}
            {data &&(
                    <Box >
                        <Typography>Last deposited on {formatDateString(data?.data?.createdAt)}</Typography>
                        {data?.cabinet?.display_name && (<Typography>From {data?.cabinet?.display_name}</Typography>)}
                    </Box>
            )}
          </Box>
  )
}

export default GetlastDeposited