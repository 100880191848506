import MovingIcon from '@mui/icons-material/Moving'
import PowerIcon from '@mui/icons-material/Power'
import SyncIcon from '@mui/icons-material/Sync'
import ThermostatIcon from '@mui/icons-material/Thermostat'
import { Box, useTheme } from "@mui/material"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useParams } from "react-router-dom"
import Header from "../../../components/Header"
import StatBox from "../../../components/StatBox"
import StatBoxLoading from "../../../components/StatBoxLoading"
import { fetchBatVitals  } from "../../../state_management/batteries"
import { tokens } from "../../../theme"
import BatteryLocation from "./BatteryLocationMap"
import BatterySwapHistoryDatagrid from './components/BatterySwapHistoryDatagrid'

const InfoBoxWrapper = ({children})=>{
  const theme = useTheme() 
  const colors = tokens(theme.palette.mode)
  

  return (
    <Box
    gridColumn={"span 3"}
    display={"flex"}
    alignItems={"center"}
    justifyContent={"center"}
    backgroundColor={colors.primary[400]}
    >
      {children}
    </Box>
  )
}

const BatteryDetail = () => {

    const {battvitals} = useSelector(state => state.batteries )

    const {batteryid} = useParams()
    const location = useLocation()
    const theme = useTheme() 
    const dispatch = useDispatch()
    const colors = tokens(theme.palette.mode)

    

    const {bpoolid} = location.state || {}


    useEffect(()=>{
        dispatch(fetchBatVitals(batteryid))
        
    },[batteryid, dispatch])

  return (
    <Box m={"20px"}>
        <Header 
          title={`MAC ID: ${batteryid}`}
          subtitle={`Batterypool Id: ${bpoolid}`}
        />
        <Box
          display={"grid"}
          gridTemplateColumns={"repeat(12, 1fr)"}
          gridAutoRows="140px"
          gap="20px"
          mb={"20px"}
        >
          <InfoBoxWrapper>
            {
              battvitals?.loading || !battvitals?.data
              ? <StatBoxLoading />
              : <StatBox 
                  title={battvitals?.data?.raw?.vital?.stateofcharge}
                  subtitle={"Current charge"}
                  icon={
                    <PowerIcon 
                      sx={{ color: colors.greenAccent[600], fontSize: "40px" }}
                    />
                  }
                />
            }
          </InfoBoxWrapper>
          <InfoBoxWrapper>
          {
              battvitals?.loading || !battvitals?.data
              ? <StatBoxLoading />
              : <StatBox 
                  title={battvitals?.data?.raw?.vital?.cycles}
                  subtitle={"Power cycles"}
                  icon={
                    <SyncIcon 
                      sx={{ color: colors.greenAccent[600], fontSize: "40px" }}
                    />
                  }
                />
            }
          </InfoBoxWrapper>
          <InfoBoxWrapper>
          {
              battvitals?.loading || !battvitals?.data
              ? <StatBoxLoading />
              : <StatBox 
                  title={battvitals?.data?.raw?.vital?.chargingstate}
                  subtitle={"Charging state"}
                  icon={
                    <MovingIcon 
                      sx={{ color: colors.greenAccent[600], fontSize: "40px" }}
                    />
                  }
                />
            }
            
          </InfoBoxWrapper>
          <InfoBoxWrapper>
          {
              battvitals?.loading || !battvitals?.data
              ? <StatBoxLoading />
              : <StatBox 
                  title={battvitals?.data?.raw?.vital?.power_plate_temp}
                  subtitle={"Temperature in celcius"}
                  icon={
                    <ThermostatIcon 
                      sx={{ color: colors.greenAccent[600], fontSize: "40px" }}
                    />
                  }
                />
            }
            
          </InfoBoxWrapper>
        </Box>

        {battvitals?.data && battvitals?.data?.raw?.location[0]?.[2] && battvitals?.data?.raw?.location[0]?.[1] && (
          <>
              <Header title={"Battery Location"}/>
              <BatteryLocation latlong={[battvitals?.data?.raw?.location[0]?.[2], battvitals?.data?.raw?.location[0]?.[1]]}/>
          </>
        )}

        <>
          <Header title={"Battery swap history"}/>
          <BatterySwapHistoryDatagrid bpoolid={bpoolid}/>
        </>


    </Box>
  )
}

export default BatteryDetail


