import React from 'react'
import { Box,  CircularProgress } from "@mui/material";

const StatBoxLoading = () => {
  return (
    <Box width="100%" m="0 30px">
        <Box display={"flex"} justifyContent={"center"} alignContent={"center"}>
            <CircularProgress />
        </Box>
    </Box>
  )
}

export default StatBoxLoading