import { configureStore } from "@reduxjs/toolkit";
import batteriesReducer, { BatteriesApi } from "./batteries";
import cabinetReducer from "./cabinetDashboard";
import cabinetDetailReducer, { CabinetDetailsApi } from "./cabinetDetails";
import cabinetMapReducer from "./cabinetMapData";
import { PaymentsApi } from "./payments";
import {SwapsApi} from "./swapapi"
import authreducer from "./auth"

export const store = configureStore({
	reducer: {
		mapinfo: cabinetMapReducer,
		cabinets: cabinetReducer,
		cabinetDetail: cabinetDetailReducer,
		[PaymentsApi.reducerPath]: PaymentsApi.reducer,
		[BatteriesApi.reducerPath]: BatteriesApi.reducer,
		[CabinetDetailsApi.reducerPath]: CabinetDetailsApi.reducer,
		[SwapsApi.reducerPath]:SwapsApi.reducer,
		batteries: batteriesReducer,
		auth: authreducer
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(
			PaymentsApi.middleware,
			BatteriesApi.middleware,
			CabinetDetailsApi.middleware, 
			SwapsApi.middleware
		),
});