import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL } from '../config'
import { saveUserInfo, getUserDeets, getAuthToken, setAuthToken } from '../utils/tokenprovider';

const LOGINURL = `${BASE_URL}/api/v1/auth/login`


export const userLogin = createAsyncThunk("user/login", async(login, thunkApi)=>{
  const {email, password} = login
  try {
      const res = await fetch(LOGINURL, {
        method:"POST",            
        headers:{
          "Content-Type": "application/json",
        },
        body: JSON.stringify({email, password})
      })

      if(!res.ok){
        throw new Error("login api issue")
      }

      const response = await res.json()

      if (response.token) {
        setAuthToken(response.token)
        const { name, email, phone, role, userid } = response
        saveUserInfo({ name, email, phone, role, userid })
      }

      return response
  } catch (error) {
    console.log("ERROR>>", error)
    return thunkApi.rejectWithValue("Could not log in")
  }
})

const getInitialState =()=>{
    const token = getAuthToken()
    const userInfo = getUserDeets()
    return {
        isAuthenticated: !!token,
        loginPending: false,
        loginError: "",
        userData: userInfo,
        token: token ,
      };
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: getInitialState(),
  reducers: {
    login: (state) => {
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.isAuthenticated = false;
    },
  },
  extraReducers:(builder)=>{
    builder
      .addCase(userLogin.pending, (state)=>{
        state.loginPending = true
        state.loginError = ""
        state.userData = null
        state.isAuthenticated = false
      })
      .addCase(userLogin.fulfilled, (state, action)=>{
        state.loginPending = false
        state.loginError = ""
        state.userData = action.payload
        state.isAuthenticated = true
        state.token = action.payload.token
      })
      .addCase(userLogin.rejected, (state)=>{
        state.loginPending = false
        state.loginError = "Something went wrong when logging in"
        state.userData = null
        state.isAuthenticated = false
      })
  }
});

export const {login, logout} = authSlice.actions;
export default authSlice.reducer;