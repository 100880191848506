import { Box } from "@mui/material";
import Header from "../../components/Header";
import SwapsGrid from "./components/SwapsGrid";
import SwapCharts from "./components/SwapCharts";

const Swaps = () => {



  return (
    <Box m="20px">
      <Header title="SWAPS" subtitle="List of Swaps" />
      <SwapCharts />
      <SwapsGrid />
    </Box>
  );
};

export default Swaps;
