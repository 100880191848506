import React from "react";
import Header from "../../../components/Header";
import EnergyConsumptionDisplay from "./EnergyConsumptionChart";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import SwapsDisplay from "./SwapsDisplay";

const Charts = ({ header, energychart,  energyUsed, swapschart, swapscount}) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	return (
		<>
			<Header title={"Charts"} />
			<Box
				display={"grid"}
				gridTemplateColumns={"repeat(12, 1fr)"}
				gridAutoRows="140px"
				gap="20px"
				mt={"20px"}
				sx={{
					marginTop: "20px",
					marginBottom: "40px",
				}}
			>
				<Box
					gridColumn="span 6"
					gridRow="span 3"
					backgroundColor={colors.primary[400]}

				>
                    <EnergyConsumptionDisplay
                        cabinetName={header}
                        data={energychart}
                        energy={energyUsed}
                    />
				</Box>
				<Box
					gridColumn="span 6"
					gridRow="span 3"
					backgroundColor={colors.primary[400]}

				>
					<SwapsDisplay 
						data={swapschart}
						swapscount={swapscount}
					/>
				</Box>
			</Box>
		</>
	);
};

export default Charts;
