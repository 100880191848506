const getStartOfDay = (timestamp) => {
	const date = new Date(timestamp);
	const midnight = new Date(
		date.getFullYear(),
		date.getMonth(),
		date.getDate()
	);
	return midnight.toISOString();
};

function formatDateString(dateString) {
	// Parse the input string into a Date object
	const date = new Date(dateString);

	// Helper function to format hours in 12-hour clock
	const formatHours = (hours) => {
		const period = hours >= 12 ? "pm" : "am";
		const hour = hours % 12 || 12; // Convert 0 to 12 for midnight
		return `${hour}${period}`;
	};

	// Helper function to format ordinal suffix for the day
	const getOrdinalSuffix = (day) => {
		if (day % 10 === 1 && day !== 11) return "st";
		if (day % 10 === 2 && day !== 12) return "nd";
		if (day % 10 === 3 && day !== 13) return "rd";
		return "th";
	};

	// Extract date components
	const hours = date.getHours();
	const day = date.getDate();
	const dayOfWeek = date.toLocaleString("en-US", { weekday: "short" }); // e.g., "Wed"
	const month = date.toLocaleString("en-US", { month: "short" }); // e.g., "Oct"

	// Build formatted string
	return `${formatHours(
		hours
	)} ${dayOfWeek} ${month} ${day}${getOrdinalSuffix(day)}`;
}



export { getStartOfDay, formatDateString };
