import {Marker, Popup} from "react-leaflet"
import MapIcon from "../../assets/images/battery.png"
import UserMapIcon from "../../assets/images/motorbike.png"
import {divIcon} from "leaflet"
import fullBattery from "../../assets/images/charge_status/fullbat.png"
import mediumBattery from "../../assets/images/charge_status/medium.png"
import emptyBattery from "../../assets/images/charge_status/empty.png"
import noBattery from "../../assets/images/charge_status/nobat.png"
import BatteryIndicator from "./BatteryIndicator"
import toast from "react-hot-toast";
import { useSelector } from "react-redux"

const MapMarker = ({mapdata}) => {

  const {position, label, active, stateOfCharge, userCurrentLocation=false } = mapdata
  const batteryNumber = stateOfCharge?.filter(x => x !== 0).length
  const {userCoordinates} = useSelector(state => state.mapinfo)    

  const customIcon = (label, active, batteryNumber, userCurrentLocation) =>{

    const borderColor = active ? "green" :"red"
    const batteryDisplay = userCurrentLocation ? "" : `, 🔋 ${" "+batteryNumber}`

    const iconHtml = `
        <div style="display: flex; flex-direction: column; justify-content: center;align-items: center;">
          <p style="border: 2px solid ${borderColor};display: flex; align-items: center;background-color: white; padding: 2px 5px; font-weight: bold; border-radius: 5px; width: fit-content;">
          <span style="white-space:nowrap;">${label}${batteryDisplay}</span>
          </p>
          <img src="${userCurrentLocation ? UserMapIcon : MapIcon}" style="width: ${userCurrentLocation ? "70px" :"40px"}; margin-top:-20px"/>

        </div>

    `

    const makeCustomIcon = divIcon({
      className:"custom-icon",
      html:iconHtml,
      iconSize: [40, 60]
    })

    return makeCustomIcon
  }


    
  return (
        <Marker position={position} icon={customIcon(label,active, batteryNumber, userCurrentLocation)}>
          <Popup>
            {userCurrentLocation
              ? <p>Where you are</p>
              : (
                <div>

                {stateOfCharge?.map((item, index)=>{
                  const slotMarker = item === 0 ? "🔳" :"🟩"
                  const slotStatus = item === 0 ? "EMPTY" :"OCCUPIED"
  
                  const indicatorIcon = 
                  item === 0 ? noBattery : 
                  item >= 1 && item < 40 ? emptyBattery : 
                  item >= 41 && item < 70 ? mediumBattery : 
                  item >= 71 && item <= 100 ? fullBattery : 
                  emptyBattery;
  
                  const chargePercentage = item === 0 ? "" : `${String(item).slice(0,3)}%`
  
                  return (
                    <p key={index} style={{display:"flex", alignItems:"center", gap:"10px"}}>
                      <span>{slotMarker}</span> 
                      <span >{slotStatus}</span>
                      <span style={{display:"flex", alignItems:"center"}}>
                        <BatteryIndicator indicatorIcon={indicatorIcon}/>
                        <span style={{marginLeft:"5px"}}>{chargePercentage}</span>
                      </span>
                    </p>
                  )
                })}
                  <div style={{
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center"
                  }}>
                    {/* eslint-disable-next-line */}
                    {userCoordinates
                      ? (
                         // eslint-disable-next-line
                          <a 
                          href={`https://www.google.com/maps/dir/?api=1&origin=${userCoordinates?.lat},${userCoordinates?.long}&destination=${position[0]},${position[1]}`} t
                          target="_blank"
                          style={{
                            display:"inline-block",
                            textDecoration:"none",
                            border: "1px solid green",
                            padding:"3px 5px",
                            borderRadius:"5px",
                            color: "black",
                            marginBottom:"10px"
                          }}
                        >
                          Get Directions
                        </a>
                      )
                      :(
                        <button
                          style={{
                            display:"inline-block",
                            textDecoration:"none",
                            border: "1px solid green",
                            padding:"3px 5px",
                            borderRadius:"5px",
                            color: "black",
                            marginBottom:"10px"
                          }}
                          onClick={()=>toast("Please allow access to your location to get directions bly clicking the center me button")}
                        >
                          Get Directions
                        </button>
                      )
                    }

                  </div>
              </div>
              )
            }
          </Popup>
        </Marker>
  )
}

export default MapMarker