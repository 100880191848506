import { Route, Routes } from "react-router-dom";
import Login from "./scenes/Authentication/Login";
import PrivateRoutes from "./scenes/Authentication/PrivateRoutes";
import Bar from "./scenes/bar";
import CabinetMap from "./scenes/cabinetMap";
import Dashboard from "./scenes/dashboard";
import FAQ from "./scenes/faq";
import Layout from "./scenes/Layout";
import Line from "./scenes/line";
import Payments from "./scenes/payments";
import Pie from "./scenes/pie";
import Swaps from "./scenes/swaps";
import Team from "./scenes/team";
import { Toaster } from "react-hot-toast";
import CabinetHomepage from "./scenes/operations/cabinets";
import OperationsPage from "./scenes/operations";
import Clients from "./scenes/operations/clients";
import Operationpayments from "./scenes/operations/payments";
import Batteries from "./scenes/operations/batteries";
import CabinetDetails from "./scenes/operations/cabinets/CabinetDetails";
import BatteryDetail from "./scenes/operations/batteries/BatteryDetail";


function App() {
  return (
    <>
          <Routes>
          {/* Public routes */}
          <Route path="/cabinetmap" element={<CabinetMap />}/>
          <Route path="/login" element={<Login />} />
          <Route element={<Layout/>}>
              {/* Private routes */}
              <Route element={<PrivateRoutes />}>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/team" element={<Team />} />
                  <Route path="/payments" element={<Payments />} />
                  <Route path="/swaps" element={<Swaps />} />
                  <Route path="/bar" element={<Bar />} />
                  <Route path="/pie" element={<Pie />} />
                  <Route path="/line" element={<Line />} />
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/operations" element={<OperationsPage />}  >
                      <Route index element={<CabinetHomepage />} /> 
                      <Route path="cabinets" element={<CabinetHomepage />}/>
                      <Route path="cabinets/:cabinetid" element={<CabinetDetails />}/>
                      <Route path="batteries" element={<Batteries />} />
                      <Route path="batteries/:batteryid" element={<BatteryDetail />} />
                      <Route path="clients" element={<Clients />} />
                      <Route path="payments" element={<Operationpayments />} />
                  </Route>
               </Route>
          </Route>
        </Routes>
        <Toaster />
    </>

  );
}

export default App;
