import { CancelOutlined, MonetizationOnOutlined } from '@mui/icons-material'
import { Box, CircularProgress, Typography, useTheme } from '@mui/material'
import { useGetCustomerPaymentQuery } from '../../../state_management/payments'
import { tokens } from '../../../theme'
import { getStartOfDay } from '../../../utils/dateUtils'

const SwapsPaymentCheck = ({customerid, createdAt}) => {

    const theme = useTheme(); // grab  theme from material ui context, set in layout.js
	const colors = tokens(theme.palette.mode);


    const starttime = getStartOfDay(createdAt)
    const {data, loading, error} = useGetCustomerPaymentQuery({
        customerid,
        startdate:starttime,
        enddate: createdAt
    })



  return (
		<Box >
			{loading ? (
				<CircularProgress />
			) : error ? (
				<div>error</div>
			) : data ? (
				<>
                    {data?.some(x => x?.payment_status === "success")
                        ? (
                            <Box
                                backgroundColor={colors.greenAccent[200]}
                                p="5px"
                                borderRadius={"3px"}
                            >
                                <Typography color={colors.grey[900]}  display={'flex'} alignItems={"center"} justifyContent={"center"} gap={"5px"}>
                                    <MonetizationOnOutlined /> Paid
                                </Typography>
                            </Box>
                        )
                        : (
                            <Box
                                backgroundColor={colors.redAccent[600]}
                                p="5px"
                                borderRadius={"3px"}
                            >
                                <Typography color={colors.grey[900]}  display={'flex'} alignItems={"center"} justifyContent={"center"} gap={"5px"}>
                                    <CancelOutlined /> No Payment
                                </Typography>
                            </Box>
                        )
                    }
                </>
			) : (
				<div> No data present</div>
			)}
		</Box>
  );
}

export default SwapsPaymentCheck