import { tokens } from "../theme";

export const mockDataTeam = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    age: 35,
    phone: "(665)121-5454",
    access: "admin",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    age: 42,
    phone: "(421)314-2288",
    access: "manager",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    age: 45,
    phone: "(422)982-6739",
    access: "user",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    age: 16,
    phone: "(921)425-6742",
    access: "admin",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    age: 31,
    phone: "(421)445-1189",
    access: "user",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    age: 150,
    phone: "(232)545-6483",
    access: "manager",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    age: 44,
    phone: "(543)124-0123",
    access: "user",
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    age: 36,
    phone: "(222)444-5555",
    access: "user",
  },
  {
    id: 9,
    name: "Harvey Roxie",
    email: "harveyroxie@gmail.com",
    age: 65,
    phone: "(444)555-6239",
    access: "admin",
  },
];


const rawpayments = {
  "currentPage": 1,
  "totalPages": 50,
  "logs": [
      {
          "method": "mpesa",
          "payment_id": "bYjlKZsDZ9hNfvUAIvcS",
          "payment_gateway_ref": "ws_CO_17042024150440053710530383",
          "time": "2024-04-17 17:34:51 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "s25de2BSIFddBHQt2KGVHT94qLo1",
              "customer_name": "Mr Peter Mburu",
              "phone": "254710530383"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "tMrvtHqrsK7hBQgObXWs",
          "payment_gateway_ref": "ws_CO_17042024142435513711649887",
          "time": "2024-04-17 16:54:50 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "zxumAyY3ylgaMJ3NQ6qK4XZwYS22",
              "customer_name": "Mr Stanley  Maranga ",
              "phone": "254711649887"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "fZmyxkVLhvwUrkO6Cxrj",
          "payment_gateway_ref": "ws_CO_17042024102118516725839203",
          "time": "2024-04-17 12:51:40 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "QK6txEPrxuZ1CATZ9Sfjsu0UvlM2",
              "customer_name": "Mr Clinton  Kinyua",
              "phone": "254725839203"
          },
          "payment_status": "failure",
          "other": "Request cancelled by user"
      },
      {
          "method": "mpesa",
          "payment_id": "cRbIK8RztLgSZC22cCcA",
          "payment_gateway_ref": "ws_CO_17042024170839021720534307",
          "time": "2024-04-17 19:38:53 GMT+5:30",
          "amount": "3",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "xocSOXXPaSfWR0zDLuckloq5uk42",
              "customer_name": "Mr Odemba Erima",
              "phone": "254720534307"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "iGYIdbE10c4vOHdqsidC",
          "payment_gateway_ref": "ws_CO_17042024183330411715227219",
          "time": "2024-04-17 21:03:48 GMT+5:30",
          "amount": "1",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "rKTv5gHVEEVoRdPS590KhuNxOBh2",
              "customer_name": "Mr Fredrick  Kathembe ",
              "phone": "254715227219"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "EPNSc9v6ZH6eIrnMUB8G",
          "payment_gateway_ref": "ws_CO_17042024191630831700134495",
          "time": "2024-04-17 21:46:51 GMT+5:30",
          "amount": "3",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "p0YMZyjFe9bItRggiR0msGBq3PU2",
              "customer_name": "Mr Douglas Kibet",
              "phone": "254700134495"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "2ySJdQNmfYSCeQNW7DxN",
          "payment_gateway_ref": "ws_CO_17042024191853786715037807",
          "time": "2024-04-17 21:49:12 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "woSBr0IPa3SM5GzVzcoBHUNrx723",
              "customer_name": "Mr Richard  Njane ",
              "phone": "254715037807"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "FzIb6lS50Jxueg2QEwB3",
          "payment_gateway_ref": "ws_CO_17042024194522539727241305",
          "time": "2024-04-17 22:16:04 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "n5Xfxc7i9hMjYSRSSzZzzY6fqTs2",
              "customer_name": "Mr Moses Njeru",
              "phone": "254727241305"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "nUlDIwT6Up6AccSyfeiT",
          "payment_gateway_ref": "ws_CO_17042024203908580729420744",
          "time": "2024-04-17 23:09:52 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "YSo2jhp3UjUU7ByBfEH7fHtn1Yh2",
              "customer_name": "Mr Gideon  Njoki",
              "phone": "254729420744"
          },
          "payment_status": "failure",
          "other": "Request cancelled by user"
      },
      {
          "method": "mpesa",
          "payment_id": "9XXDSJBYzyHcZXFyz51C",
          "payment_gateway_ref": "ws_CO_17042024204005331729420744",
          "time": "2024-04-17 23:10:51 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "YSo2jhp3UjUU7ByBfEH7fHtn1Yh2",
              "customer_name": "Mr Gideon  Njoki",
              "phone": "254729420744"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "WtjLaMwO2Jcjjnbtj1NN",
          "payment_gateway_ref": "ws_CO_17042024224724276729420744",
          "time": "2024-04-18 01:17:30 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "YSo2jhp3UjUU7ByBfEH7fHtn1Yh2",
              "customer_name": "Mr Gideon  Njoki",
              "phone": "254729420744"
          },
          "payment_status": "failure",
          "other": "DS timeout user cannot be reached"
      },
      {
          "method": "mpesa",
          "payment_id": "jGdYwuSWFOORL0RpnXaP",
          "payment_gateway_ref": "ws_CO_17042024224740464729420744",
          "time": "2024-04-18 01:17:44 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "YSo2jhp3UjUU7ByBfEH7fHtn1Yh2",
              "customer_name": "Mr Gideon  Njoki",
              "phone": "254729420744"
          },
          "payment_status": "failure",
          "other": "DS timeout user cannot be reached"
      },
      {
          "method": "mpesa",
          "payment_id": "v7WjzaB8TduX2Ldhp4xH",
          "payment_gateway_ref": "ws_CO_17042024224809155729420744",
          "time": "2024-04-18 01:18:14 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "YSo2jhp3UjUU7ByBfEH7fHtn1Yh2",
              "customer_name": "Mr Gideon  Njoki",
              "phone": "254729420744"
          },
          "payment_status": "failure",
          "other": "DS timeout user cannot be reached"
      },
      {
          "method": "mpesa",
          "payment_id": "VuSPTKOXhq2AHCehyN4B",
          "payment_gateway_ref": "ws_CO_17042024224824179729420744",
          "time": "2024-04-18 01:18:57 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "YSo2jhp3UjUU7ByBfEH7fHtn1Yh2",
              "customer_name": "Mr Gideon  Njoki",
              "phone": "254729420744"
          },
          "payment_status": "failure",
          "other": "The initiator information is invalid."
      },
      {
          "method": "mpesa",
          "payment_id": "EAGCkIef5n0HBB8ytCMs",
          "payment_gateway_ref": "ws_CO_17042024224919277729420744",
          "time": "2024-04-18 01:19:25 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "YSo2jhp3UjUU7ByBfEH7fHtn1Yh2",
              "customer_name": "Mr Gideon  Njoki",
              "phone": "254729420744"
          },
          "payment_status": "failure",
          "other": "DS timeout user cannot be reached"
      },
      {
          "method": "mpesa",
          "payment_id": "dyyQTSN76TP5Z11EgApK",
          "payment_gateway_ref": "ws_CO_17042024230538324729420744",
          "time": "2024-04-18 01:35:43 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "YSo2jhp3UjUU7ByBfEH7fHtn1Yh2",
              "customer_name": "Mr Gideon  Njoki",
              "phone": "254729420744"
          },
          "payment_status": "failure",
          "other": "DS timeout user cannot be reached"
      },
      {
          "method": "mpesa",
          "payment_id": "JegQZcvJBkIqED7r0YSd",
          "payment_gateway_ref": "ws_CO_17042024230637184729420744",
          "time": "2024-04-18 01:37:13 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "YSo2jhp3UjUU7ByBfEH7fHtn1Yh2",
              "customer_name": "Mr Gideon  Njoki",
              "phone": "254729420744"
          },
          "payment_status": "failure",
          "other": "DS timeout user cannot be reached"
      },
      {
          "method": "mpesa",
          "payment_id": "3LLZCWeYiu0Gxpeylszl",
          "payment_gateway_ref": "ws_CO_17042024232139494729420744",
          "time": "2024-04-18 01:51:47 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "YSo2jhp3UjUU7ByBfEH7fHtn1Yh2",
              "customer_name": "Mr Gideon  Njoki",
              "phone": "254729420744"
          },
          "payment_status": "failure",
          "other": "DS timeout user cannot be reached"
      },
      {
          "method": "mpesa",
          "payment_id": "EX7WC0JYynf0iVAy8HfJ",
          "payment_gateway_ref": "ws_CO_17042024232156755729420744",
          "time": "2024-04-18 01:52:01 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "YSo2jhp3UjUU7ByBfEH7fHtn1Yh2",
              "customer_name": "Mr Gideon  Njoki",
              "phone": "254729420744"
          },
          "payment_status": "failure",
          "other": "DS timeout user cannot be reached"
      },
      {
          "method": "mpesa",
          "payment_id": "xcrQzhh2NMdRtL2vkggi",
          "payment_gateway_ref": "ws_CO_17042024232214251729420744",
          "time": "2024-04-18 01:52:19 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "YSo2jhp3UjUU7ByBfEH7fHtn1Yh2",
              "customer_name": "Mr Gideon  Njoki",
              "phone": "254729420744"
          },
          "payment_status": "failure",
          "other": "DS timeout user cannot be reached"
      },
      {
          "method": "mpesa",
          "payment_id": "AFAViCJD1KX0T3HG1ZwO",
          "payment_gateway_ref": "ws_CO_17042024232229923729420744",
          "time": "2024-04-18 01:52:34 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "YSo2jhp3UjUU7ByBfEH7fHtn1Yh2",
              "customer_name": "Mr Gideon  Njoki",
              "phone": "254729420744"
          },
          "payment_status": "failure",
          "other": "DS timeout user cannot be reached"
      },
      {
          "method": "mpesa",
          "payment_id": "ZmYAa8puFiw95dvnCyHp",
          "payment_gateway_ref": "ws_CO_17042024233019875729420744",
          "time": "2024-04-18 02:00:28 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "YSo2jhp3UjUU7ByBfEH7fHtn1Yh2",
              "customer_name": "Mr Gideon  Njoki",
              "phone": "254729420744"
          },
          "payment_status": "failure",
          "other": "DS timeout user cannot be reached"
      },
      {
          "method": "mpesa",
          "payment_id": "vtGNylWU8jNwFF09xjzO",
          "payment_gateway_ref": "ws_CO_17042024233036704729420744",
          "time": "2024-04-18 02:00:41 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "YSo2jhp3UjUU7ByBfEH7fHtn1Yh2",
              "customer_name": "Mr Gideon  Njoki",
              "phone": "254729420744"
          },
          "payment_status": "failure",
          "other": "DS timeout user cannot be reached"
      },
      {
          "method": "mpesa",
          "payment_id": "Jrax7RYmNfGMeeNSx2do",
          "payment_gateway_ref": "ws_CO_18042024051601880724569651",
          "time": "2024-04-18 07:46:11 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "LClK6WoWfRfm3hLaFXwgrytssvj1",
              "customer_name": "Mr Rogers Mulee",
              "phone": "254724569651"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "H0lxhlJ6BkHvgzJnfZGV",
          "payment_gateway_ref": "ws_CO_18042024064035354719169486",
          "time": "2024-04-18 09:10:48 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "IbB5v8HM3DOm3hzIFozrhT4W7ZV2",
              "customer_name": "Mr Michael  Nyabwanga",
              "phone": "254719169486"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "dY7zFMPDW9megKcxBZ81",
          "payment_gateway_ref": "ws_CO_18042024073319507712474298",
          "time": "2024-04-18 10:03:25 GMT+5:30",
          "amount": "3",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "L8pDIc7aHGcrTYnbuNqi461EwRG2",
              "customer_name": "Mr Daniel Etyang",
              "phone": "254712474298"
          },
          "payment_status": "failure",
          "other": "Request cancelled by user"
      },
      {
          "method": "mpesa",
          "payment_id": "OUTmrmeQYqBC75z6qK5s",
          "payment_gateway_ref": "ws_CO_18042024073444875712474298",
          "time": "2024-04-18 10:04:58 GMT+5:30",
          "amount": "3",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "L8pDIc7aHGcrTYnbuNqi461EwRG2",
              "customer_name": "Mr Daniel Etyang",
              "phone": "254712474298"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "RFPyWPPjiY8tlMU1XOCf",
          "payment_gateway_ref": "ws_CO_18042024082752388724545889",
          "time": "2024-04-18 10:58:09 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "yfbYxGXGR4VSGW2xDspsWXsrsof1",
              "customer_name": "Mr Antony Mungai",
              "phone": "254724545889"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "CX8Is0miwnqplER0jUv6",
          "payment_gateway_ref": "ws_CO_18042024084243752723280593",
          "time": "2024-04-18 11:12:58 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "EhiGlLYdWjSLfXsj73YHjgmc7N83",
              "customer_name": "Mr George Thuo",
              "phone": "254723280593"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "mxkASitbT73NX32JXecZ",
          "payment_gateway_ref": "ws_CO_18042024085904628725871693",
          "time": "2024-04-18 11:29:19 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "MS8rxGDQZSXI1pryl34iPmVRxQs1",
              "customer_name": "Mr DAVID KIOI",
              "phone": "254725871693"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "v4QBtM9UiTeqKuYd8AL8",
          "payment_gateway_ref": "ws_CO_18042024090522909728782354",
          "time": "2024-04-18 11:35:39 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "lphOfv7jKOZRTtihgb15W3vrDzA3",
              "customer_name": "Mr Martin  Waweru",
              "phone": "254728782354"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "66a5fZ9JOKvw32m4AA9v",
          "payment_gateway_ref": "ws_CO_18042024090934525702724192",
          "time": "2024-04-18 11:39:47 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "5CI6Lyh3mWec2WoV5fI73wxydKu2",
              "customer_name": "Mr NEWTON KINYUA",
              "phone": "254702724192"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "b7yeSteQIdo9cYnMDkph",
          "payment_gateway_ref": "ws_CO_18042024093505422794870643",
          "time": "2024-04-18 12:05:31 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "SthMe57kwNUhYcnr0Anv6pLRJcO2",
              "customer_name": "Mr Victor Kulecho",
              "phone": "254794870643"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "T3qTmq2KgiHwYLWqzR6S",
          "payment_gateway_ref": "ws_CO_18042024093924086720946928",
          "time": "2024-04-18 12:09:41 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "0aS6JKIslvQOSXkLwHO21AkYtUv1",
              "customer_name": "Mr John Kinyua ",
              "phone": "254720946928"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "KaQlDz22tQldLQNJSWz4",
          "payment_gateway_ref": "ws_CO_18042024095516515721903802",
          "time": "2024-04-18 12:25:28 GMT+5:30",
          "amount": "600",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "CgQenKOb1sTr6alxRlmtLorf7st2",
              "customer_name": "Mr John Njukia",
              "phone": "254721903802"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "HLHobXWJks5lEDetfXqU",
          "payment_gateway_ref": "ws_CO_18042024100056257727414919",
          "time": "2024-04-18 12:31:11 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "fQe2xVhOURdw6B6aHwvfl6NsqXy2",
              "customer_name": "Mr SIMON MUHIU",
              "phone": "254727414919"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "OSz1dZhGxwUh9isQjpFP",
          "payment_gateway_ref": "ws_CO_18042024101231977726342686",
          "time": "2024-04-18 12:42:44 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "f4BYqf09kUV8phF5Jx9PvYbDC4C3",
              "customer_name": "Mr Misheck Kaburu",
              "phone": "254726342686"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "1ANXwVxiU02EB9EancWK",
          "payment_gateway_ref": "ws_CO_18042024101237121702056930",
          "time": "2024-04-18 12:42:57 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "nS6zF6iERCNUsMf9cAUULpgnucY2",
              "customer_name": "Mr Sosnes Marende",
              "phone": "254702056930"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "dmG92G0qUQTUVh2ncOe4",
          "payment_gateway_ref": "ws_CO_18042024101415394722715443",
          "time": "2024-04-18 12:44:26 GMT+5:30",
          "amount": "3",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "xhGPDbp5l8Y1InjpFdLzJ2rpdK42",
              "customer_name": "Mr Alexander Ketter",
              "phone": "254722715443"
          },
          "payment_status": "failure",
          "other": "The initiator information is invalid."
      },
      {
          "method": "mpesa",
          "payment_id": "HIXve8tVtMDs6TFTeXpW",
          "payment_gateway_ref": "ws_CO_18042024101435930722715443",
          "time": "2024-04-18 12:45:02 GMT+5:30",
          "amount": "3",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "xhGPDbp5l8Y1InjpFdLzJ2rpdK42",
              "customer_name": "Mr Alexander Ketter",
              "phone": "254722715443"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "BqRk3xC25AzGQNBaEKmM",
          "payment_gateway_ref": "ws_CO_18042024101701116723667690",
          "time": "2024-04-18 12:47:10 GMT+5:30",
          "amount": "3",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "FuwomypAyVSMZ5tCWSo1pzGkbnJ3",
              "customer_name": "Mr Pius Kirwa",
              "phone": "254723667690"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "lKrwNZHahS4uFQjxnuF1",
          "payment_gateway_ref": "ws_CO_18042024102002840724867267",
          "time": "2024-04-18 12:50:13 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "wSKVwOms9uaIX3x4wdYtR36Zyyn2",
              "customer_name": "Mr Marack Mbugua",
              "phone": "254724867267"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "aKteqfPOfWQKtGyuw0Ug",
          "payment_gateway_ref": "ws_CO_18042024101959902728747460",
          "time": "2024-04-18 12:50:18 GMT+5:30",
          "amount": "3",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "3v0Xktme34ZhD0eAhxqIWDAqVPl1",
              "customer_name": "Mr Vincent Opuya",
              "phone": "254728747460"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "uXKsIEeaz8c25lhhSiPk",
          "payment_gateway_ref": "ws_CO_18042024104205661712963155",
          "time": "2024-04-18 13:12:16 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "ptXVkFcgSeRemQaMrzNmKUy5auD2",
              "customer_name": "Mr Manasse Akkwale",
              "phone": "254712963155"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "NF2GDYdRegMOsW94dqEy",
          "payment_gateway_ref": "ws_CO_18042024104528715712348754",
          "time": "2024-04-18 13:15:41 GMT+5:30",
          "amount": "3",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "2AYw3HJm0fPHkcavl5Gy8Zq1pbn1",
              "customer_name": "Mr Dennis  Mulongo",
              "phone": "254712348754"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "a5uuKtsbVQ8m3UzY4USC",
          "payment_gateway_ref": "ws_CO_18042024104840748721731667",
          "time": "2024-04-18 13:18:58 GMT+5:30",
          "amount": "3",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "UHDXTbxkBcY6ji3SXBtgKN9x3rQ2",
              "customer_name": "Mr Johnstone Soo",
              "phone": "254721731667"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "HxWjmWsn6T7J5S6qN5wk",
          "payment_gateway_ref": "ws_CO_18042024105315286705642015",
          "time": "2024-04-18 13:23:44 GMT+5:30",
          "amount": "1",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "cz6jpUKyiRVKxD75Je3U2rlt5JA2",
              "customer_name": "Mr Anthony  Kambo",
              "phone": "254705642015"
          },
          "payment_status": "failure",
          "other": "Request cancelled by user"
      },
      {
          "method": "mpesa",
          "payment_id": "zcZEwfWGsK0OrQJgCweB",
          "payment_gateway_ref": "ws_CO_18042024105715807705642015",
          "time": "2024-04-18 13:27:35 GMT+5:30",
          "amount": "1",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "cz6jpUKyiRVKxD75Je3U2rlt5JA2",
              "customer_name": "Mr Anthony  Kambo",
              "phone": "254705642015"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "S4Q9VcXiMEUpgdAFp0TK",
          "payment_gateway_ref": "ws_CO_18042024112706680725839203",
          "time": "2024-04-18 13:57:18 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "QK6txEPrxuZ1CATZ9Sfjsu0UvlM2",
              "customer_name": "Mr Clinton  Kinyua",
              "phone": "254725839203"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "4UFwH02wapVRA9K2ofaf",
          "payment_gateway_ref": "ws_CO_18042024113126197768674058",
          "time": "2024-04-18 14:01:40 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "kWrxy2Hhcid9rkT9hrtwDtg8cQD2",
              "customer_name": "Mr James Kool",
              "phone": "254768674058"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "8sL2aOq5BYnvyuh8R9WX",
          "payment_gateway_ref": "ws_CO_18042024113543245729420744",
          "time": "2024-04-18 14:05:54 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "YSo2jhp3UjUU7ByBfEH7fHtn1Yh2",
              "customer_name": "Mr Gideon  Njoki",
              "phone": "254729420744"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "bXen7udUXP27itRPkzxW",
          "payment_gateway_ref": "ws_CO_18042024114041853710621112",
          "time": "2024-04-18 14:10:55 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "kd2f6FsH8oM9gZpnz6YgeHi0VPp2",
              "customer_name": "Mr Hudson Mugadi",
              "phone": "254710621112"
          },
          "payment_status": "failure",
          "other": "Request cancelled by user"
      },
      {
          "method": "mpesa",
          "payment_id": "dBfJNy0qKqJi5L3LDDHa",
          "payment_gateway_ref": "ws_CO_18042024114128002710621112",
          "time": "2024-04-18 14:11:41 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "kd2f6FsH8oM9gZpnz6YgeHi0VPp2",
              "customer_name": "Mr Hudson Mugadi",
              "phone": "254710621112"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "ihiM44vApTo5WlqzWMJl",
          "payment_gateway_ref": "ws_CO_18042024114254382714838101",
          "time": "2024-04-18 14:13:05 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "MvU2PepyyvdMv7Sy0RAY2lIH9xu2",
              "customer_name": "Mr Jackson Wachira",
              "phone": "254714838101"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "VMaD8PYvgFAjkBSibTNi",
          "payment_gateway_ref": "ws_CO_18042024120824507768918089",
          "time": "2024-04-18 14:38:38 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "IadZjFhVg6fetFC1lF11zmKho0y1",
              "customer_name": "Mr Simon Gakunga ",
              "phone": "254768918089"
          },
          "payment_status": "failure",
          "other": "The balance is insufficient for the transaction."
      },
      {
          "method": "mpesa",
          "payment_id": "S8kUY310gyAFGSLU60d5",
          "payment_gateway_ref": "ws_CO_18042024120928301768918089",
          "time": "2024-04-18 14:39:42 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "IadZjFhVg6fetFC1lF11zmKho0y1",
              "customer_name": "Mr Simon Gakunga ",
              "phone": "254768918089"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "bc1n19yOXrTzBbRPtzJi",
          "payment_gateway_ref": "ws_CO_18042024122127477722702657",
          "time": "2024-04-18 14:51:44 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "aa32Lqk5T7TX7gxwP4pyUjrcSeN2",
              "customer_name": "Mr Kogutu Onyangi",
              "phone": "254722702657"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "7cwmU2BazaHaA96PJV9z",
          "payment_gateway_ref": "ws_CO_18042024125426467727241305",
          "time": "2024-04-18 15:24:42 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "n5Xfxc7i9hMjYSRSSzZzzY6fqTs2",
              "customer_name": "Mr Moses Njeru",
              "phone": "254727241305"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "mjoyEhKOM82PwX5R5Xo2",
          "payment_gateway_ref": "ws_CO_18042024131024611711608801",
          "time": "2024-04-18 15:40:36 GMT+5:30",
          "amount": "3",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "P9dtG8UIvobLMUlBZa6qqFAJhnA2",
              "customer_name": "Mr Samuel Chesoli",
              "phone": "254711608801"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "67USTskoWxpJMcwT1KmN",
          "payment_gateway_ref": "ws_CO_18042024131337246724413569",
          "time": "2024-04-18 15:43:49 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "C19G8jwwTVU8ZtlahFC2O0lpY913",
              "customer_name": "Mr Johnson  Wandia",
              "phone": "254724413569"
          },
          "payment_status": "failure",
          "other": "Request cancelled by user"
      },
      {
          "method": "mpesa",
          "payment_id": "LAIad3uoODiGzUdaB98m",
          "payment_gateway_ref": "ws_CO_18042024132113613790982245",
          "time": "2024-04-18 15:51:29 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "Y9uIwpCSpkSJOOcxr9K8FKjmz1E2",
              "customer_name": "Mr Joshua Mwithya",
              "phone": "254790982245"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "qIOcTpBqreRrBI1AER54",
          "payment_gateway_ref": "ws_CO_18042024132128267715037807",
          "time": "2024-04-18 15:51:41 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "woSBr0IPa3SM5GzVzcoBHUNrx723",
              "customer_name": "Mr Richard  Njane ",
              "phone": "254715037807"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "DSiBM5qGVp55glsDFol7",
          "payment_gateway_ref": "ws_CO_18042024132717212791173864",
          "time": "2024-04-18 15:57:27 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "bFarhZje0UULSJrmCvtJfzPXqvE2",
              "customer_name": "Mr James  Melwish",
              "phone": "254791173864"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "DSpcFra0ppwhj6YgkuhV",
          "payment_gateway_ref": "ws_CO_18042024134643410710530383",
          "time": "2024-04-18 16:16:53 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "s25de2BSIFddBHQt2KGVHT94qLo1",
              "customer_name": "Mr Peter Mburu",
              "phone": "254710530383"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "NNF4uZFjv1Spn4SRj6e6",
          "payment_gateway_ref": "ws_CO_18042024135730495740699552",
          "time": "2024-04-18 16:27:46 GMT+5:30",
          "amount": "3",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "fBGvuyaHGuQtMyTlLi1tTSvwRtC2",
              "customer_name": "Mr Fidelis Wabwire",
              "phone": "254740699552"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "gWQsa2GX6p9QUV87G5SL",
          "payment_gateway_ref": "ws_CO_18042024142835930700134495",
          "time": "2024-04-18 16:58:47 GMT+5:30",
          "amount": "3",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "p0YMZyjFe9bItRggiR0msGBq3PU2",
              "customer_name": "Mr Douglas Kibet",
              "phone": "254700134495"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "YxjTjqNOBYCamLp1SZge",
          "payment_gateway_ref": "ws_CO_18042024143649521720534307",
          "time": "2024-04-18 17:07:02 GMT+5:30",
          "amount": "3",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "xocSOXXPaSfWR0zDLuckloq5uk42",
              "customer_name": "Mr Odemba Erima",
              "phone": "254720534307"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "KhqYEKqNt0jJ3673wVQr",
          "payment_gateway_ref": "ws_CO_18042024145500134114835558",
          "time": "2024-04-18 17:25:15 GMT+5:30",
          "amount": "1",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "EDGI0rFiWtd8AvSLHyH964fAynt1",
              "customer_name": "Mr Elijah  Wambete",
              "phone": "254114835558"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "D1befWm2ZdgbHjAqcqLi",
          "payment_gateway_ref": "ws_CO_18042024155409872711649887",
          "time": "2024-04-18 18:24:27 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "zxumAyY3ylgaMJ3NQ6qK4XZwYS22",
              "customer_name": "Mr Stanley  Maranga ",
              "phone": "254711649887"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "FQwoTMw60K15ptOk5jzf",
          "payment_gateway_ref": "ws_CO_18042024164539957710884665",
          "time": "2024-04-18 19:15:55 GMT+5:30",
          "amount": "3",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "sxOxfWNuAMVmMVCGLclvQpK1Lqr2",
              "customer_name": "Mr Wickliffe  Mitalo",
              "phone": "254710884665"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "aHyUtJwujgQ0fJ6nQgHR",
          "payment_gateway_ref": "ws_CO_18042024174754762794578231",
          "time": "2024-04-18 20:18:08 GMT+5:30",
          "amount": "3",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "cTaOhhY2TKNwlaUKMwTyLYLtSdm2",
              "customer_name": "Mr Domnic Kiio",
              "phone": "254794578231"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "tQjeMPJBTgZEgwntgtbl",
          "payment_gateway_ref": "ws_CO_18042024175043829715227219",
          "time": "2024-04-18 20:20:58 GMT+5:30",
          "amount": "1",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "rKTv5gHVEEVoRdPS590KhuNxOBh2",
              "customer_name": "Mr Fredrick  Kathembe ",
              "phone": "254715227219"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "FtedGSltiso2eHfEI1MP",
          "payment_gateway_ref": "ws_CO_18042024182356378712589654",
          "time": "2024-04-18 20:54:11 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "A90f8tHqpMhsFtF28qPo61dr2if2",
              "customer_name": "Mr Paul Mburu",
              "phone": "254712589654"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "trK7eY73sulJMkWN9g27",
          "payment_gateway_ref": "ws_CO_18042024183636556722561372",
          "time": "2024-04-18 21:06:45 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "u82zaedUF1fs9vwaPuoNBQ7h36z2",
              "customer_name": "Mr Milton  Otieno",
              "phone": "254722561372"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "my6clnAP4GgqOTkVLcr4",
          "payment_gateway_ref": "ws_CO_18042024192208157712723541",
          "time": "2024-04-18 21:52:24 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "Hp8N4zD6YkU0zvjC8u2WyUScND22",
              "customer_name": "Mr Kyalo Onesmas",
              "phone": "254712723541"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "XyLtYEfGMfNniWBUXUik",
          "payment_gateway_ref": "ws_CO_19042024003616139712963155",
          "time": "2024-04-19 03:06:28 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "ptXVkFcgSeRemQaMrzNmKUy5auD2",
              "customer_name": "Mr Manasse Akkwale",
              "phone": "254712963155"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "h469CfWB6Q1Cf89y0C7i",
          "payment_gateway_ref": "ws_CO_19042024045310722724569651",
          "time": "2024-04-19 07:23:20 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "LClK6WoWfRfm3hLaFXwgrytssvj1",
              "customer_name": "Mr Rogers Mulee",
              "phone": "254724569651"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "ZxqZHiKJWEquVjKB1Evf",
          "payment_gateway_ref": "ws_CO_19042024052602180719169486",
          "time": "2024-04-19 07:56:18 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "IbB5v8HM3DOm3hzIFozrhT4W7ZV2",
              "customer_name": "Mr Michael  Nyabwanga",
              "phone": "254719169486"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "ZEwJwagZNq1AocZnEmVH",
          "payment_gateway_ref": "ws_CO_19042024071811450715227219",
          "time": "2024-04-19 09:48:24 GMT+5:30",
          "amount": "1",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "rKTv5gHVEEVoRdPS590KhuNxOBh2",
              "customer_name": "Mr Fredrick  Kathembe ",
              "phone": "254715227219"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "F0f6TcXr5VnjvASEDzhs",
          "payment_gateway_ref": "ws_CO_19042024072023639712348754",
          "time": "2024-04-19 09:50:39 GMT+5:30",
          "amount": "3",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "2AYw3HJm0fPHkcavl5Gy8Zq1pbn1",
              "customer_name": "Mr Dennis  Mulongo",
              "phone": "254712348754"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "Y7BBciCYmrkbITavUtGb",
          "payment_gateway_ref": "ws_CO_19042024074833277724545889",
          "time": "2024-04-19 10:18:50 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "yfbYxGXGR4VSGW2xDspsWXsrsof1",
              "customer_name": "Mr Antony Mungai",
              "phone": "254724545889"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "AqQKGZN9nrF4wFIl3SvT",
          "payment_gateway_ref": "ws_CO_19042024091901811710884665",
          "time": "2024-04-19 11:49:14 GMT+5:30",
          "amount": "3",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "sxOxfWNuAMVmMVCGLclvQpK1Lqr2",
              "customer_name": "Mr Wickliffe  Mitalo",
              "phone": "254710884665"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "ea6Sgt8l0hSjpOmtr3iG",
          "payment_gateway_ref": "ws_CO_19042024092638841725537708",
          "time": "2024-04-19 11:57:12 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "waiixSlD5lZDse9qgK1saDcjetr1",
              "customer_name": "Mr Moses Parteri",
              "phone": "254725537708"
          },
          "payment_status": "failure",
          "other": "DS timeout user cannot be reached"
      },
      {
          "method": "mpesa",
          "payment_id": "W01rALJZvRE4fDH8mO9S",
          "payment_gateway_ref": "ws_CO_19042024093507704794870643",
          "time": "2024-04-19 12:05:32 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "SthMe57kwNUhYcnr0Anv6pLRJcO2",
              "customer_name": "Mr Victor Kulecho",
              "phone": "254794870643"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "uOT8hZ52mtXZzFhQ7xoU",
          "payment_gateway_ref": "ws_CO_19042024100643649727414919",
          "time": "2024-04-19 12:36:58 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "fQe2xVhOURdw6B6aHwvfl6NsqXy2",
              "customer_name": "Mr SIMON MUHIU",
              "phone": "254727414919"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "2TbH9R8Gm3G68AbnHSTt",
          "payment_gateway_ref": "ws_CO_19042024100745999725839203",
          "time": "2024-04-19 12:37:56 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "QK6txEPrxuZ1CATZ9Sfjsu0UvlM2",
              "customer_name": "Mr Clinton  Kinyua",
              "phone": "254725839203"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "Ec3fyMKV3ZO59lQa8PJW",
          "payment_gateway_ref": "ws_CO_19042024100824522723280593",
          "time": "2024-04-19 12:38:39 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "EhiGlLYdWjSLfXsj73YHjgmc7N83",
              "customer_name": "Mr George Thuo",
              "phone": "254723280593"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "2OugcwokEI2qAgVtUCEG",
          "payment_gateway_ref": "ws_CO_19042024104244274722702657",
          "time": "2024-04-19 13:13:21 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "aa32Lqk5T7TX7gxwP4pyUjrcSeN2",
              "customer_name": "Mr Kogutu Onyangi",
              "phone": "254722702657"
          },
          "payment_status": "failure",
          "other": "DS timeout user cannot be reached"
      },
      {
          "method": "mpesa",
          "payment_id": "V5EjuRKBiPuG80bsje1s",
          "payment_gateway_ref": "ws_CO_19042024104449810710573813",
          "time": "2024-04-19 13:15:15 GMT+5:30",
          "amount": "6300",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "7RCMzfCuRNVx6hSNS1xd3MEEL1t2",
              "customer_name": "Mr Timothy  Nandwa",
              "phone": "254710573813"
          },
          "payment_status": "failure",
          "other": "Request cancelled by user"
      },
      {
          "method": "mpesa",
          "payment_id": "SQbteN8jSBRxq2ql0Cpi",
          "payment_gateway_ref": "ws_CO_19042024104729120726342686",
          "time": "2024-04-19 13:17:40 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "f4BYqf09kUV8phF5Jx9PvYbDC4C3",
              "customer_name": "Mr Misheck Kaburu",
              "phone": "254726342686"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "JeB4wvM8yAkEx0R6ySkq",
          "payment_gateway_ref": "ws_CO_19042024110217608714838101",
          "time": "2024-04-19 13:32:29 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "MvU2PepyyvdMv7Sy0RAY2lIH9xu2",
              "customer_name": "Mr Jackson Wachira",
              "phone": "254714838101"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "8DkIuV3d1zThLRZjakPL",
          "payment_gateway_ref": "ws_CO_19042024111015834723667690",
          "time": "2024-04-19 13:40:26 GMT+5:30",
          "amount": "3",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "FuwomypAyVSMZ5tCWSo1pzGkbnJ3",
              "customer_name": "Mr Pius Kirwa",
              "phone": "254723667690"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "yeVgUJPNWDVgXvNWD4Zo",
          "payment_gateway_ref": "ws_CO_19042024112043410726646888",
          "time": "2024-04-19 13:50:55 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "s7hCQbGVfbZmjkSrY9QPxniClYQ2",
              "customer_name": "Mr Nicholas Murithi",
              "phone": "254726646888"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "NYxDKOJLTl3zeU1z1NI4",
          "payment_gateway_ref": "ws_CO_19042024112738302720946928",
          "time": "2024-04-19 13:57:59 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "0aS6JKIslvQOSXkLwHO21AkYtUv1",
              "customer_name": "Mr John Kinyua ",
              "phone": "254720946928"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "wN3ipIvi7puNIiSkIFYF",
          "payment_gateway_ref": "ws_CO_19042024113137358711608801",
          "time": "2024-04-19 14:01:49 GMT+5:30",
          "amount": "3",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "P9dtG8UIvobLMUlBZa6qqFAJhnA2",
              "customer_name": "Mr Samuel Chesoli",
              "phone": "254711608801"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "MGPn0MLMAWGb0Ju5ymok",
          "payment_gateway_ref": "ws_CO_19042024114318619722702657",
          "time": "2024-04-19 14:13:29 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "aa32Lqk5T7TX7gxwP4pyUjrcSeN2",
              "customer_name": "Mr Kogutu Onyangi",
              "phone": "254722702657"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "hfQLcgXvLEPEUAGpquLK",
          "payment_gateway_ref": "ws_CO_19042024114710240705642015",
          "time": "2024-04-19 14:17:22 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "cz6jpUKyiRVKxD75Je3U2rlt5JA2",
              "customer_name": "Mr Anthony  Kambo",
              "phone": "254705642015"
          },
          "payment_status": "failure",
          "other": "Request cancelled by user"
      },
      {
          "method": "mpesa",
          "payment_id": "vOSJMmoPfAqgAsnoAlsK",
          "payment_gateway_ref": "ws_CO_19042024114917228768674058",
          "time": "2024-04-19 14:19:35 GMT+5:30",
          "amount": "450",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "kWrxy2Hhcid9rkT9hrtwDtg8cQD2",
              "customer_name": "Mr James Kool",
              "phone": "254768674058"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "rfAVV1FIgJfsvC2tETqG",
          "payment_gateway_ref": "ws_CO_19042024115452997712474298",
          "time": "2024-04-19 14:25:09 GMT+5:30",
          "amount": "3",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "L8pDIc7aHGcrTYnbuNqi461EwRG2",
              "customer_name": "Mr Daniel Etyang",
              "phone": "254712474298"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      },
      {
          "method": "mpesa",
          "payment_id": "WJRMS4nL5tXLNtAqHTwg",
          "payment_gateway_ref": "ws_CO_19042024122452037791268252",
          "time": "2024-04-19 14:55:02 GMT+5:30",
          "amount": "1",
          "currency": "ksh",
          "location": {
              "lat": "123",
              "long": "123"
          },
          "swap_id": "",
          "customer": {
              "customer_id": "8zg2Da92B1ekJ9AP6RHxg8ngWa82",
              "customer_name": "Mr Eliud Kiptoo",
              "phone": "254791268252"
          },
          "payment_status": "success",
          "other": "The service request is processed successfully."
      }
  ]
}

export const payments = ()=>{
  return rawpayments.logs?.map((item)=>{
    const newLine = {
        id: item?.payment_id,
        amount: item?.amount,
        customerName: item?.customer?.customer_name,
        customerPhone: item?.customer?.phone, 
        payment_status: item?.payment_status,
        message: item?.other
    }

    return newLine
})
}


export const mockDataContacts = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    age: 35,
    phone: "(665)121-5454",
    address: "0912 Won Street, Alabama, SY 10001",
    city: "New York",
    zipCode: "10001",
    registrarId: 123512,
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    age: 42,
    phone: "(421)314-2288",
    address: "1234 Main Street, New York, NY 10001",
    city: "New York",
    zipCode: "13151",
    registrarId: 123512,
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    age: 45,
    phone: "(422)982-6739",
    address: "3333 Want Blvd, Estanza, NAY 42125",
    city: "New York",
    zipCode: "87281",
    registrarId: 4132513,
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    age: 16,
    phone: "(921)425-6742",
    address: "1514 Main Street, New York, NY 22298",
    city: "New York",
    zipCode: "15551",
    registrarId: 123512,
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    age: 31,
    phone: "(421)445-1189",
    address: "11122 Welping Ave, Tenting, CD 21321",
    city: "Tenting",
    zipCode: "14215",
    registrarId: 123512,
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    age: 150,
    phone: "(232)545-6483",
    address: "1234 Canvile Street, Esvazark, NY 10001",
    city: "Esvazark",
    zipCode: "10001",
    registrarId: 123512,
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    age: 44,
    phone: "(543)124-0123",
    address: "22215 Super Street, Everting, ZO 515234",
    city: "Evertin",
    zipCode: "51523",
    registrarId: 123512,
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    age: 36,
    phone: "(222)444-5555",
    address: "4123 Ever Blvd, Wentington, AD 142213",
    city: "Esteras",
    zipCode: "44215",
    registrarId: 512315,
  },
  {
    id: 9,
    name: "Harvey Roxie",
    email: "harveyroxie@gmail.com",
    age: 65,
    phone: "(444)555-6239",
    address: "51234 Avery Street, Cantory, ND 212412",
    city: "Colunza",
    zipCode: "111234",
    registrarId: 928397,
  },
  {
    id: 10,
    name: "Enteri Redack",
    email: "enteriredack@gmail.com",
    age: 42,
    phone: "(222)444-5555",
    address: "4123 Easer Blvd, Wentington, AD 142213",
    city: "Esteras",
    zipCode: "44215",
    registrarId: 533215,
  },
  {
    id: 11,
    name: "Steve Goodman",
    email: "stevegoodmane@gmail.com",
    age: 11,
    phone: "(444)555-6239",
    address: "51234 Fiveton Street, CunFory, ND 212412",
    city: "Colunza",
    zipCode: "1234",
    registrarId: 92197,
  },
];





export const mockDataInvoices = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    cost: "21.24",
    phone: "(665)121-5454",
    date: "03/12/2022",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    cost: "1.24",
    phone: "(421)314-2288",
    date: "06/15/2021",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    cost: "11.24",
    phone: "(422)982-6739",
    date: "05/02/2022",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    cost: "80.55",
    phone: "(921)425-6742",
    date: "03/21/2022",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    cost: "1.24",
    phone: "(421)445-1189",
    date: "01/12/2021",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    cost: "63.12",
    phone: "(232)545-6483",
    date: "11/02/2022",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    cost: "52.42",
    phone: "(543)124-0123",
    date: "02/11/2022",
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    cost: "21.24",
    phone: "(222)444-5555",
    date: "05/02/2021",
  },
];

export const mockTransactions = [
  {
    txId: "01e4dsa",
    user: "johndoe",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "0315dsaa",
    user: "jackdower",
    date: "2022-04-01",
    cost: "133.45",
  },
  {
    txId: "01e4dsa",
    user: "aberdohnny",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "51034szv",
    user: "goodmanave",
    date: "2022-11-05",
    cost: "200.95",
  },
  {
    txId: "0a123sb",
    user: "stevebower",
    date: "2022-11-02",
    cost: "13.55",
  },
  {
    txId: "01e4dsa",
    user: "aberdohnny",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "120s51a",
    user: "wootzifer",
    date: "2019-04-15",
    cost: "24.20",
  },
  {
    txId: "0315dsaa",
    user: "jackdower",
    date: "2022-04-01",
    cost: "133.45",
  },
];

export const mockBarData = [
  {
    country: "LEXO",
    "swaps today": 137,
    "swaps todayColor": "hsl(229, 70%, 50%)",
    burger: 96,
    burgerColor: "hsl(296, 70%, 50%)",
    kebab: 72,
    kebabColor: "hsl(97, 70%, 50%)",
    yesterday: 140,
    yesterdayColor: "hsl(340, 70%, 50%)",
  },
  {
    country: "SHELL",
    "swaps today": 55,
    "swaps todayColor": "hsl(307, 70%, 50%)",
    burger: 28,
    burgerColor: "hsl(111, 70%, 50%)",
    kebab: 58,
    kebabColor: "hsl(273, 70%, 50%)",
    yesterday: 29,
    yesterdayColor: "hsl(275, 70%, 50%)",
  },
  {
    country: "WEST",
    "swaps today": 109,
    "swaps todayColor": "hsl(72, 70%, 50%)",
    burger: 23,
    burgerColor: "hsl(96, 70%, 50%)",
    kebab: 34,
    kebabColor: "hsl(106, 70%, 50%)",
    yesterday: 152,
    yesterdayColor: "hsl(256, 70%, 50%)",
  },
  {
    country: "AG",
    "swaps today": 133,
    "swaps todayColor": "hsl(257, 70%, 50%)",
    burger: 52,
    burgerColor: "hsl(326, 70%, 50%)",
    kebab: 43,
    kebabColor: "hsl(110, 70%, 50%)",
    yesterday: 83,
    yesterdayColor: "hsl(9, 70%, 50%)",
  },
  {
    country: "EASTL",
    "swaps today": 81,
    "swaps todayColor": "hsl(190, 70%, 50%)",
    burger: 80,
    burgerColor: "hsl(325, 70%, 50%)",
    kebab: 112,
    kebabColor: "hsl(54, 70%, 50%)",
    yesterday: 35,
    yesterdayColor: "hsl(285, 70%, 50%)",
  },
  {
    country: "CBD",
    "swaps today": 66,
    "swaps todayColor": "hsl(208, 70%, 50%)",
    burger: 111,
    burgerColor: "hsl(334, 70%, 50%)",
    kebab: 167,
    kebabColor: "hsl(182, 70%, 50%)",
    yesterday: 18,
    yesterdayColor: "hsl(76, 70%, 50%)",
  },
  {
    country: "KAREN",
    "swaps today": 80,
    "swaps todayColor": "hsl(87, 70%, 50%)",
    burger: 47,
    burgerColor: "hsl(141, 70%, 50%)",
    kebab: 158,
    kebabColor: "hsl(224, 70%, 50%)",
    yesterday: 49,
    yesterdayColor: "hsl(274, 70%, 50%)",
  },
];

export const mockPieData = [
  {
    id: "hack",
    label: "hack",
    value: 239,
    color: "hsl(104, 70%, 50%)",
  },
  {
    id: "make",
    label: "make",
    value: 170,
    color: "hsl(162, 70%, 50%)",
  },
  {
    id: "go",
    label: "go",
    value: 322,
    color: "hsl(291, 70%, 50%)",
  },
  {
    id: "lisp",
    label: "lisp",
    value: 503,
    color: "hsl(229, 70%, 50%)",
  },
  {
    id: "scala",
    label: "scala",
    value: 584,
    color: "hsl(344, 70%, 50%)",
  },
];

export const mockLineData = [
  {
    id: "Lexo",
    color: tokens("dark").greenAccent[500],
    data: [
      {
        x: "6AM",
        y: 101,
      },
      {
        x: "7AM",
        y: 75,
      },
      {
        x: "8AM",
        y: 36,
      },
      {
        x: "9AM",
        y: 216,
      },
      {
        x: "10AM",
        y: 35,
      },
      {
        x: "11AM",
        y: 236,
      },
      {
        x: "12PM",
        y: 88,
      },
      {
        x: "1PM",
        y: 232,
      },
      {
        x: "2PM",
        y: 281,
      },
      {
        x: "3PM",
        y: 1,
      },
      {
        x: "4PM",
        y: 35,
      },
      {
        x: "5PM",
        y: 14,
      },
    ],
  },
  {
    id: "Karen",
    color: tokens("dark").blueAccent[300],
    data: [
      {
        x: "6AM",
        y: 212,
      },
      {
        x: "7AM",
        y: 190,
      },
      {
        x: "8AM",
        y: 270,
      },
      {
        x: "9AM",
        y: 9,
      },
      {
        x: "10AM",
        y: 75,
      },
      {
        x: "11AM",
        y: 175,
      },
      {
        x: "12PM",
        y: 33,
      },
      {
        x: "1PM",
        y: 189,
      },
      {
        x: "2PM",
        y: 97,
      },
      {
        x: "3PM",
        y: 87,
      },
      {
        x: "4PM",
        y: 299,
      },
      {
        x: "5PM",
        y: 251,
      },
    ],
  },
  {
    id: "Shell",
    color: tokens("dark").redAccent[200],
    data: [
      {
        x: "6AM",
        y: 191,
      },
      {
        x: "7AM",
        y: 136,
      },
      {
        x: "8AM",
        y: 91,
      },
      {
        x: "9AM",
        y: 190,
      },
      {
        x: "10AM",
        y: 211,
      },
      {
        x: "11AM",
        y: 152,
      },
      {
        x: "12PM",
        y: 189,
      },
      {
        x: "1PM",
        y: 152,
      },
      {
        x: "2PM",
        y: 8,
      },
      {
        x: "3PM",
        y: 197,
      },
      {
        x: "4PM",
        y: 107,
      },
      {
        x: "5PM",
        y: 170,
      },
    ],
  },
];

export const mockGeographyData = [
  {
    id: "AFG",
    value: 520600,
  },
  {
    id: "AGO",
    value: 949905,
  },
  {
    id: "ALB",
    value: 329910,
  },
  {
    id: "ARE",
    value: 675484,
  },
  {
    id: "ARG",
    value: 432239,
  },
  {
    id: "ARM",
    value: 288305,
  },
  {
    id: "ATA",
    value: 415648,
  },
  {
    id: "ATF",
    value: 665159,
  },
  {
    id: "AUT",
    value: 798526,
  },
  {
    id: "AZE",
    value: 481678,
  },
  {
    id: "BDI",
    value: 496457,
  },
  {
    id: "BEL",
    value: 252276,
  },
  {
    id: "BEN",
    value: 440315,
  },
  {
    id: "BFA",
    value: 343752,
  },
  {
    id: "BGD",
    value: 920203,
  },
  {
    id: "BGR",
    value: 261196,
  },
  {
    id: "BHS",
    value: 421551,
  },
  {
    id: "BIH",
    value: 974745,
  },
  {
    id: "BLR",
    value: 349288,
  },
  {
    id: "BLZ",
    value: 305983,
  },
  {
    id: "BOL",
    value: 430840,
  },
  {
    id: "BRN",
    value: 345666,
  },
  {
    id: "BTN",
    value: 649678,
  },
  {
    id: "BWA",
    value: 319392,
  },
  {
    id: "CAF",
    value: 722549,
  },
  {
    id: "CAN",
    value: 332843,
  },
  {
    id: "CHE",
    value: 122159,
  },
  {
    id: "CHL",
    value: 811736,
  },
  {
    id: "CHN",
    value: 593604,
  },
  {
    id: "CIV",
    value: 143219,
  },
  {
    id: "CMR",
    value: 630627,
  },
  {
    id: "COG",
    value: 498556,
  },
  {
    id: "COL",
    value: 660527,
  },
  {
    id: "CRI",
    value: 60262,
  },
  {
    id: "CUB",
    value: 177870,
  },
  {
    id: "-99",
    value: 463208,
  },
  {
    id: "CYP",
    value: 945909,
  },
  {
    id: "CZE",
    value: 500109,
  },
  {
    id: "DEU",
    value: 63345,
  },
  {
    id: "DJI",
    value: 634523,
  },
  {
    id: "DNK",
    value: 731068,
  },
  {
    id: "DOM",
    value: 262538,
  },
  {
    id: "DZA",
    value: 760695,
  },
  {
    id: "ECU",
    value: 301263,
  },
  {
    id: "EGY",
    value: 148475,
  },
  {
    id: "ERI",
    value: 939504,
  },
  {
    id: "ESP",
    value: 706050,
  },
  {
    id: "EST",
    value: 977015,
  },
  {
    id: "ETH",
    value: 461734,
  },
  {
    id: "FIN",
    value: 22800,
  },
  {
    id: "FJI",
    value: 18985,
  },
  {
    id: "FLK",
    value: 64986,
  },
  {
    id: "FRA",
    value: 447457,
  },
  {
    id: "GAB",
    value: 669675,
  },
  {
    id: "GBR",
    value: 757120,
  },
  {
    id: "GEO",
    value: 158702,
  },
  {
    id: "GHA",
    value: 893180,
  },
  {
    id: "GIN",
    value: 877288,
  },
  {
    id: "GMB",
    value: 724530,
  },
  {
    id: "GNB",
    value: 387753,
  },
  {
    id: "GNQ",
    value: 706118,
  },
  {
    id: "GRC",
    value: 377796,
  },
  {
    id: "GTM",
    value: 66890,
  },
  {
    id: "GUY",
    value: 719300,
  },
  {
    id: "HND",
    value: 739590,
  },
  {
    id: "HRV",
    value: 929467,
  },
  {
    id: "HTI",
    value: 538961,
  },
  {
    id: "HUN",
    value: 146095,
  },
  {
    id: "IDN",
    value: 490681,
  },
  {
    id: "IND",
    value: 549818,
  },
  {
    id: "IRL",
    value: 630163,
  },
  {
    id: "IRN",
    value: 596921,
  },
  {
    id: "IRQ",
    value: 767023,
  },
  {
    id: "ISL",
    value: 478682,
  },
  {
    id: "ISR",
    value: 963688,
  },
  {
    id: "ITA",
    value: 393089,
  },
  {
    id: "JAM",
    value: 83173,
  },
  {
    id: "JOR",
    value: 52005,
  },
  {
    id: "JPN",
    value: 199174,
  },
  {
    id: "KAZ",
    value: 181424,
  },
  {
    id: "KEN",
    value: 60946,
  },
  {
    id: "KGZ",
    value: 432478,
  },
  {
    id: "KHM",
    value: 254461,
  },
  {
    id: "OSA",
    value: 942447,
  },
  {
    id: "KWT",
    value: 414413,
  },
  {
    id: "LAO",
    value: 448339,
  },
  {
    id: "LBN",
    value: 620090,
  },
  {
    id: "LBR",
    value: 435950,
  },
  {
    id: "LBY",
    value: 75091,
  },
  {
    id: "LKA",
    value: 595124,
  },
  {
    id: "LSO",
    value: 483524,
  },
  {
    id: "LTU",
    value: 867357,
  },
  {
    id: "LUX",
    value: 689172,
  },
  {
    id: "LVA",
    value: 742980,
  },
  {
    id: "MAR",
    value: 236538,
  },
  {
    id: "MDA",
    value: 926836,
  },
  {
    id: "MDG",
    value: 840840,
  },
  {
    id: "MEX",
    value: 353910,
  },
  {
    id: "MKD",
    value: 505842,
  },
  {
    id: "MLI",
    value: 286082,
  },
  {
    id: "MMR",
    value: 915544,
  },
  {
    id: "MNE",
    value: 609500,
  },
  {
    id: "MNG",
    value: 410428,
  },
  {
    id: "MOZ",
    value: 32868,
  },
  {
    id: "MRT",
    value: 375671,
  },
  {
    id: "MWI",
    value: 591935,
  },
  {
    id: "MYS",
    value: 991644,
  },
  {
    id: "NAM",
    value: 701897,
  },
  {
    id: "NCL",
    value: 144098,
  },
  {
    id: "NER",
    value: 312944,
  },
  {
    id: "NGA",
    value: 862877,
  },
  {
    id: "NIC",
    value: 90831,
  },
  {
    id: "NLD",
    value: 281879,
  },
  {
    id: "NOR",
    value: 224537,
  },
  {
    id: "NPL",
    value: 322331,
  },
  {
    id: "NZL",
    value: 86615,
  },
  {
    id: "OMN",
    value: 707881,
  },
  {
    id: "PAK",
    value: 158577,
  },
  {
    id: "PAN",
    value: 738579,
  },
  {
    id: "PER",
    value: 248751,
  },
  {
    id: "PHL",
    value: 557292,
  },
  {
    id: "PNG",
    value: 516874,
  },
  {
    id: "POL",
    value: 682137,
  },
  {
    id: "PRI",
    value: 957399,
  },
  {
    id: "PRT",
    value: 846430,
  },
  {
    id: "PRY",
    value: 720555,
  },
  {
    id: "QAT",
    value: 478726,
  },
  {
    id: "ROU",
    value: 259318,
  },
  {
    id: "RUS",
    value: 268735,
  },
  {
    id: "RWA",
    value: 136781,
  },
  {
    id: "ESH",
    value: 151957,
  },
  {
    id: "SAU",
    value: 111821,
  },
  {
    id: "SDN",
    value: 927112,
  },
  {
    id: "SDS",
    value: 966473,
  },
  {
    id: "SEN",
    value: 158085,
  },
  {
    id: "SLB",
    value: 178389,
  },
  {
    id: "SLE",
    value: 528433,
  },
  {
    id: "SLV",
    value: 353467,
  },
  {
    id: "ABV",
    value: 251,
  },
  {
    id: "SOM",
    value: 445243,
  },
  {
    id: "SRB",
    value: 202402,
  },
  {
    id: "SUR",
    value: 972121,
  },
  {
    id: "SVK",
    value: 319923,
  },
  {
    id: "SVN",
    value: 728766,
  },
  {
    id: "SWZ",
    value: 379669,
  },
  {
    id: "SYR",
    value: 16221,
  },
  {
    id: "TCD",
    value: 101273,
  },
  {
    id: "TGO",
    value: 498411,
  },
  {
    id: "THA",
    value: 506906,
  },
  {
    id: "TJK",
    value: 613093,
  },
  {
    id: "TKM",
    value: 327016,
  },
  {
    id: "TLS",
    value: 607972,
  },
  {
    id: "TTO",
    value: 936365,
  },
  {
    id: "TUN",
    value: 898416,
  },
  {
    id: "TUR",
    value: 237783,
  },
  {
    id: "TWN",
    value: 878213,
  },
  {
    id: "TZA",
    value: 442174,
  },
  {
    id: "UGA",
    value: 720710,
  },
  {
    id: "UKR",
    value: 74172,
  },
  {
    id: "URY",
    value: 753177,
  },
  {
    id: "USA",
    value: 658725,
  },
  {
    id: "UZB",
    value: 550313,
  },
  {
    id: "VEN",
    value: 707492,
  },
  {
    id: "VNM",
    value: 538907,
  },
  {
    id: "VUT",
    value: 650646,
  },
  {
    id: "PSE",
    value: 476078,
  },
  {
    id: "YEM",
    value: 957751,
  },
  {
    id: "ZAF",
    value: 836949,
  },
  {
    id: "ZMB",
    value: 714503,
  },
  {
    id: "ZWE",
    value: 405217,
  },
  {
    id: "KOR",
    value: 171135,
  },
];
