import {useState} from 'react'
import { useGetBatterySwapHistoryQuery } from '../../../../state_management/batteries'
import { Box, useTheme , Button, Typography} from '@mui/material'
import { tokens } from '../../../../theme'
import {formatDateString} from "../../../../utils/dateUtils"
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import SwapsPaymentCheck from "../../operationscomponents/SwapsPaymentCheck";
import { DataGrid } from '@mui/x-data-grid'
import BatterySwapState from './BatterySwapState'
import { useGetBpoolCabinetQuery } from '../../../../state_management/cabinetDetails'

export const CabinetName =({cabinetbpoolid})=>{
	
	const {data} = useGetBpoolCabinetQuery({
		bpoolid:cabinetbpoolid
	})

	return (
		<Box>
			{data?.cabinet?.display_name && data?.cabinet?.display_name}
		</Box>
	)
}

const BatterySwapHistoryDatagrid = ({bpoolid}) => {
	const theme = useTheme(); // grab  theme from material ui context, set in layout.js
	const colors = tokens(theme.palette.mode);
    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(70)

    const {data, isLoading} = useGetBatterySwapHistoryQuery({
        page, 
        pagesize: limit, 
        batterypoolid: bpoolid
    })

	const columns = [
		{
            field: "status", 
            headerName: "DEPOSITED/DISPATCHED", 
            renderCell: ({ row }) => {
				return (
                    <BatterySwapState row={row} id={bpoolid}/>
                )
			},
            flex: 1,
        },
		{
			field: "customer_name",
			headerName: "CUSTOMER",
			cellClassName: "name-column--cell",
			flex: 1,
		},
		{
			field: "provider_timestamp",
			headerName: "TIME",
			flex: 1,
			cellClassName: "name-column--cell",
			align: "center",
			headerAlign: "center",
			renderCell: ({ row: { provider_timestamp } }) => {
				return `${formatDateString(provider_timestamp)}`;
			},
		},
		{
			field: "deposited_battery_id",
			headerName: "DEPOSITED BAT", 
			flex: 1,
		},
		{
			field: "dispatched_battery_id",
			headerName: "DISPATCHED BAT",
			flex: 1,
			cellClassName: "name-column--cell",
			align: "center",
			headerAlign: "center",
		},
        {
			field: "cabinet",
			headerName: "CABINETNAME", 
			flex: 1,
			renderCell: ({row})=>{
				return(
					<CabinetName cabinetbpoolid={row?.cabinet_id}/>
				)
			}
		},
		{
			field: "corresponding_customer_payment",
			headerName: "PAYMENT RECORDED",
			flex: 1,
			cellClassName: "name-column--cell",
			align: "center",
			headerAlign: "center",
			renderCell: ({ row }) => {
				return (
					<SwapsPaymentCheck
						customerid={row?.customer_id}
						createdAt={row?.createdAt}
					/>
				);
			},
		},
		{
			field: "swap_status",
			headerName: "SWAP STATUS",
			flex: 1,
			cellClassName: "name-column--cell",
			align: "center",
			headerAlign: "center",
			renderCell: ({ row: { swap_status } }) => {
				return (
					<Box
						width="60%"
						// m="0 auto"
						p="5px"
						display="flex"
						justifyContent="center"
						backgroundColor={
							swap_status === "swap_complete"
								? colors.greenAccent[600]
								: colors.greenAccent[700]
						}
						borderRadius="4px"
					>
						{swap_status === "swap_complete" ? (
							<DoneIcon />
						) : (
							<ClearIcon />
						)}
						<Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
							{swap_status === "swap_complete"
								? "Sucessful"
								: "Unsucessful"}
						</Typography>
					</Box>
				);
			},
		},
		{
			field: "view",
			headerName: "VIEW CUSTOMER DETAILS",
			flex: 1,
			renderCell: ({ row: { customer_id } }) => {
				return (
					<Button
						variant="Primary"
						sx={{
							backgroundColor: colors.blueAccent[400],
							color: colors.blueAccent[900],
						}}
						onClick={() => console.log(customer_id)}
					>
						View Details
					</Button>
				);
			},
		},
	];
    

  return (
    <Box marginTop={"20px"}>
        <Box
            height={'70vh'}
            sx={{
                "& .MuiDataGrid-root":{border:"none"},
                "& .MuiDataGrid-cell":{borderBottom:"none"},
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.blueAccent[700],
                    //color: theme.palette.secondary[100],
                    borderBottom: "none",
                    },
                "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
                },
            }}
        >
            <DataGrid 
                loading={isLoading || !data}
                getRowId={(row) => row?.createdAt}
                columns={columns}
                rows={(data && data.SwapsLog) || []}
                rowsPerPageOptions={[20, 50, 100]}
                page={page}
                pageSize={limit}
                pagination
                paginationMode="server"
                onPageChange={(newpage) => setPage(newpage)}
                onPageSizeChange={(newpagesize)=> setLimit(newpagesize)}
                rowCount={(data && data?.total) || 0}
            />
        </Box>
    </Box>
  )
}

export default BatterySwapHistoryDatagrid