import { AUTH_TOKEN_KEY, USER_DETAILS_KEY } from "../config";


export const setAuthToken = (token) => {
	localStorage.setItem(AUTH_TOKEN_KEY, token);
};

export const getAuthToken = () => {
	const token = localStorage.getItem(AUTH_TOKEN_KEY);

	if (token) {
		return token;
	}
	return null;
};

export const saveUserInfo = (userInfo) => {
	try {
		const userString = JSON.stringify(userInfo);
		localStorage.setItem(USER_DETAILS_KEY, userString);
	} catch (error) {
		console.error("Failed to save user info to localStorage:", error);
	}
};

export const getUserDeets = () => {
	try {
		const userString = localStorage.getItem(USER_DETAILS_KEY);
		return userString ? JSON.parse(userString) : null;
	} catch (error) {
		console.error("Failed to parse user info from localStorage:", error);
		return null;
	}
};

export const clearLocalStorage = (key) => {
	localStorage.removeItem(key);
};
