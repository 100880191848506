import { Box, Typography, TextField, CircularProgress, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { userLogin } from "../../state_management/auth";
import logo from "../../assets/images/phivelogo.avif"
import { tokens } from "../../theme";
import { Navigate } from "react-router-dom";

function Login() {
	const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const {  loginPending, isAuthenticated } = useSelector((state) => state.auth);


	function onSubmit() {

		dispatch(userLogin({ email, password }));
	}

	if(isAuthenticated){
		return <Navigate to="/" />
	}

	return (
		<Box display={"flex"}  sx={{
      margin: "-10px", 
      minHeight:"99vh"
    }}>
			<Box
				flex={1}
				bgcolor={colors.blueAccent[900]}
        sx={{
          display:"grid",
          placeContent:"center"
        }}
			>
					<Box
						component={"img"}
						src={logo}
						width={"100%"}
						height={"50px"}
					/>
			</Box>
			<Box
				flex={2.5}
				display={"flex"}
				flexDirection={"column"}
				justifyContent={"space-between"}
			>
				<Box
					display={"flex"}
					justifyContent={"center"}
					alignItems={"center"}
					flex={1}
				>
					<Box p={"20px"} width={"500px"}>
						<Typography color={"black"} fontSize={"24px"}>
							Log in
						</Typography>
						<Typography>
							Contact Admin for your log in credentials
						</Typography>
						<TextField
							id="outlined-email-input"
							label="Email"
							type="text"
							autoComplete="current-email"
							sx={{ marginTop: "20px", width: "100%" }}
							onChange={(e) => setEmail(e.target.value)}
							value={email}
						/>
						<TextField
							id="outlined-password-input"
							label="password"
							type="password"
							autoComplete="current-password"
							sx={{ marginTop: "20px", width: "100%" }}
							onChange={(e) => setPassword(e.target.value)}
							value={password}
						/>
						<button
							style={{
								display: "flex",
								width: "100%",
								cursor: "pointer",
								textAlign: "center",
								paddingBlock: "12px",
								fontWeight: "bold",
								marginTop: "20px",
								color: "white",
								backgroundColor: loginPending
									? colors.blueAccent[700]
									: colors.blueAccent[200],
								borderRadius: "3px",
								border: "none",
								alignItems: "center",
								justifyContent: "center",
							}}
							disabled={loginPending}
							onClick={onSubmit}
						>
							{loginPending ? (
								<CircularProgress
									color="grey"
									size={20}
									sx={{ marginLeft: "10px" }}
								/>
							) : (
								"LOG IN"
							)}
						</button>
					</Box>
				</Box>
				<Typography alignSelf={"center"} color={"grey"}>
					©Powerhive 2024 - Privacy . Contacts . Cookies . Help . FAQs
				</Typography>
			</Box>
		</Box>
	);
}

export default Login;
