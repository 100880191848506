import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import { Box, useTheme } from "@mui/material";
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../components/Header";
import StatBox from "../../../components/StatBox";
import StatBoxLoading from '../../../components/StatBoxLoading';
import { getLatestBatteryTelemetry } from '../../../state_management/batteries';
import { tokens } from "../../../theme";
import BatteriesDatgrid from './components/BatteriesDatgrid';



const Batteries = () => {

  const theme = useTheme() 
  const colors = tokens(theme.palette.mode)
  const dispatch = useDispatch()
  const {latestBatteryTelemetry} = useSelector(state => state.batteries )

  console.log("BATTERY TELEMETRY", latestBatteryTelemetry)






  useEffect(()=>{
    if(!latestBatteryTelemetry?.data){
      dispatch(getLatestBatteryTelemetry())
    }


  },[dispatch, latestBatteryTelemetry?.data])

  return (
    <Box m="20px">
      <Header 
        title={"Batteries"}
        subtitle={"Batteries in circulation"}
      />
      <Box
        display={"grid"}
        gridTemplateColumns={"repeat(12, 1fr)"}
        gridAutoRows={"140px"}
        gap="20px"
      >
        <Box
          gridColumn={"span 4"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          backgroundColor={colors.primary[400]}
        >

          {
            latestBatteryTelemetry?.loading || !latestBatteryTelemetry?.data 
            ? <StatBoxLoading />
            : latestBatteryTelemetry?.error
            ? <StatBoxLoading />
            : <StatBox 
                title={latestBatteryTelemetry?.data?.raw?.length}
                subtitle={"Total number of batteries"}
                increase={latestBatteryTelemetry?.data?.raw?.length}
                icon={
                  <Battery0BarIcon 
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                  />
                }
              />
          }

        </Box>
        <Box
          gridColumn={"span 4"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          backgroundColor={colors.primary[400]}
        >
          {
            latestBatteryTelemetry?.loading || !latestBatteryTelemetry?.data 
            ? <StatBoxLoading />
            : latestBatteryTelemetry?.error
            ? <StatBoxLoading />
            : <StatBox 
                title={latestBatteryTelemetry?.data?.activeBatteryCount}
                subtitle={"Active batteries"}
                increase={latestBatteryTelemetry?.data?.activeBatteryCount}
                icon={
                  <Battery0BarIcon 
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                  />
                }
              />
          }
        </Box>
        <Box
          gridColumn={"span 4"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          backgroundColor={colors.primary[400]}
        >
          {
            latestBatteryTelemetry?.loading || !latestBatteryTelemetry?.data 
            ? <StatBoxLoading />
            : latestBatteryTelemetry?.error
            ? <StatBoxLoading />
            : <StatBox 
                title={latestBatteryTelemetry?.data?.inactiveBatteryCount}
                subtitle={"Inactive batteries"}
                increase={latestBatteryTelemetry?.data?.inactiveBatteryCount}
                icon={
                  <Battery0BarIcon 
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                  />
                }
              />
          }
        </Box>
      </Box>
      <BatteriesDatgrid />
    </Box>
  )
}

export default Batteries