/* eslint-disable */

import {useState, useEffect} from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { Box, Button, Typography, useTheme,  TextField, CircularProgress} from "@mui/material";
import Header from '../../../components/Header';
import { tokens } from '../../../theme';
import CabinetSwapList from '../operationscomponents/CabinetSwapList';
import BatteryHistory from './BatteryHistory';
import BatteryDisplay from './BatteryDisplay';
import { useSelector, useDispatch } from 'react-redux';
import { fetchEnergyUsageChart, fetchSwapsData } from '../../../state_management/cabinetDetails';
import Charts from './Charts';
import { fetchSwaps, seedSwaps } from '../../../state_management/cabinetDetails';


const CabinetDetails = () => {
   
    const {cabinetid} = useParams()
    const location = useLocation()
    const {info} = location.state || {}
    const dispatch = useDispatch()
    const {energychart, swapschart, swaps} = useSelector(state => state.cabinetDetail)
    const [header, setHeader] = useState(info?.display_name)
    const [batteries, setBatteries] = useState(info?.cabinet_data)
    const [energyUsedByCabinet, setEnergyUsedByCabinet] = useState(info?.energyUsed)
    const [swapBegin, setSwapBegin] = useState("")
    const [swapEnd, setSwapEnd] = useState("")



    useEffect(()=>{
      dispatch(fetchEnergyUsageChart(cabinetid))
      dispatch(fetchSwapsData(cabinetid))
        dispatch(seedSwaps(info?.swapsToday))
    },[dispatch, cabinetid])




    const fetchNewSwapsList = ()=>{
      if(swapBegin && swapEnd){
        dispatch(fetchSwaps({cabinetid, startdate:swapBegin, enddate:swapEnd}))
      }
    }

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
		<Box m={"20px"}>
			<Header title={header} subtitle={"Cabinet id : " + cabinetid} />
			<BatteryDisplay batteries={batteries} />
			<Charts
				header={header}
				energychart={energychart}
				energyUsed={energyUsedByCabinet}
				swapschart={swapschart}
				swapscount={info?.swapsToday?.length}
			/>
			<Header title={`Swaps conducted at ${header} (default is today) use filter to filter swaps by date`} />
			<>
				<Box display={"flex"} gap={"10px"} alignItems={"center"}>
					<TextField
						label="Starting Time"
						type="datetime-local"
						value={swapBegin}
						onChange={(e) => setSwapBegin(e.target.value)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
					<TextField
						label="End Time"
						type="datetime-local"
						value={swapEnd}
						onChange={(e) => setSwapEnd(e.target.value)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
					<Button
						variant="Primary"
						sx={{
							backgroundColor: colors.blueAccent[400],
							color: colors.blueAccent[900],
							paddingY: "15px",
						}}
						onClick={() => fetchNewSwapsList()}
					>
						Filter
					</Button>
				</Box>
				{swaps.loading ? (
					<Box
						display={"flex"}
						justifyContent={"center"}
						alignContent={"center"}
						sx={{ backgroundColor: colors.primary[400], m:"20px 0", p:"20px 0" }}
					>
						<CircularProgress />
					</Box>
				) : swaps?.data?.raw !== 0 ? (
					<CabinetSwapList swaps={swaps?.data?.raw} />
				) : (
					<Typography>
						No swaps recorded check back later or select a date
						range
					</Typography>
				)}
			</>

			{/* <Header 
        title={'Cabinet Battery history'}
        subtitle={"Batteries in cabinet one hour ago"}
      />
      <Box
        display={'flex'}
        // justifyContent={'center'}
        alignItems={'center'}
        gap={"20px"}
      >
        <Button
            variant='Primary'
            sx={{
              backgroundColor:colors.blueAccent[400],
              color: colors.blueAccent[900],
              "&:hover": {
                backgroundColor: colors.blueAccent[300], 
                color: colors.blueAccent[800],           
            },
            }}
            onClick={()=> {}}
        >
          Today
        </Button>
        <Button
            variant='Primary'
            sx={{
              backgroundColor:colors.blueAccent[400],
              color: colors.blueAccent[900],
              "&:hover": {
                backgroundColor: colors.blueAccent[300], 
                color: colors.blueAccent[800],           
            },
            }}
            onClick={()=> {}}
        >
          Yesterday
        </Button>
        <Button
            variant='Primary'
            sx={{
              backgroundColor:colors.blueAccent[400],
              color: colors.blueAccent[900],
              "&:hover": {
                backgroundColor: colors.blueAccent[300], 
                color: colors.blueAccent[800],           
            },
            }}
            onClick={()=> {}}
        >
          Enter custom date range
        </Button>
      </Box>
      <BatteryHistory /> */}
		</Box>
	);
}

export default CabinetDetails