import { Box } from "@mui/material";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, TileLayer } from "react-leaflet";

const BatteryLocation = ({latlong})=>{
  
    const customIcon= new Icon({
        iconUrl:"https://cdn-icons-png.flaticon.com/128/684/684908.png",
        iconSize:[38, 38]
    })

    return (
        <Box sx={{ height: '40vh', width: '100%' }} mb={"20px"}>
        <MapContainer 
          center={latlong} 
          zoom={13} 
          style={{ height: "100%", width: "100%" }}
        >
          <TileLayer 
            url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" 
          />
          {/* <Marker position={[-1.286389, 36.817223]} /> */}
          <Marker position={latlong} icon={customIcon}>

          </Marker>
        </MapContainer>
      </Box>

    )
}


export default BatteryLocation