import React from "react";
import { Box, Typography, useTheme, Button } from "@mui/material";
import { tokens } from "../../../theme";
import { DataGrid } from "@mui/x-data-grid";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import SwapsPaymentCheck from "./SwapsPaymentCheck";
import { formatDateString } from "../../../utils/dateUtils";

// import { useNavigate } from "react-router-dom";

const CabinetSwapList = ({swaps}) => {
	// const navigate = useNavigate();

	const theme = useTheme(); // grab  theme from material ui context, set in layout.js
	const colors = tokens(theme.palette.mode);
	const columns = [
		{ field: "id", headerName: "ID", },
		{
			field: "customer_name",
			headerName: "CUSTOMER",
			cellClassName: "name-column--cell",
			flex: 1,
		},
		{
			field: "provider_timestamp",
			headerName: "TIME",
			flex: 1,
			cellClassName: "name-column--cell",
			align: "center",
			headerAlign: "center",
			renderCell: ({row:{provider_timestamp}})=>{
				return `${formatDateString(provider_timestamp)}`
			}
			
		},
		{
			field: "deposited_battery_id",
			headerName: "DEPOSITED BAT", // active inactive
			flex: 1,
		},
		{
			field: "dispatched_battery_id",
			headerName: "DISPATCHED BAT",
			flex: 1,
			cellClassName: "name-column--cell",
			align: "center",
			headerAlign: "center",
		},
		{
			field: "corresponding_customer_payment",
			headerName: "PAYMENT RECORDED",
			flex: 1,
			cellClassName: "name-column--cell",
			align: "center",
			headerAlign: "center",
			renderCell: ({row})=>{
				return <SwapsPaymentCheck customerid={row?.customer_id} createdAt={row?.createdAt} />
			}
		},
		{
			field: "swap_status",
			headerName: "SWAP STATUS",
			flex: 1,
			cellClassName: "name-column--cell",
			align: "center",
			headerAlign: "center",
			renderCell: ({row:{swap_status}})=>{
				return(
					<Box
					width="60%"
					// m="0 auto"
					p="5px"
					display="flex"
					justifyContent="center"
					backgroundColor={
					  swap_status ==="swap_complete"
						? colors.greenAccent[600]
						: colors.greenAccent[700]
					}
					borderRadius="4px"
				  >
					{swap_status ==="swap_complete" ? <DoneIcon /> :<ClearIcon />}
					<Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
					  {swap_status ===  "swap_complete" ? "Sucessful" :"Unsucessful"}
					</Typography>
				  </Box>
				)
			}
			
		},
		{
			field: "view",
			headerName: "VIEW CUSTOMER DETAILS",
			flex: 1,
			renderCell: ({ row: { customer_id } }) => {
				return (
					<Button
						variant="Primary"
						sx={{
							backgroundColor: colors.blueAccent[400],
							color: colors.blueAccent[900],
						}}
						onClick={() => console.log(customer_id)}
					>
						View Details
					</Button>
				);
			},
		},
	];

	

	return (
			<Box
				m="20px 0"
				height="50vh"
				sx={{
					"& .MuiDataGrid-root": {
						border: "none",
					},
					"& .MuiDataGrid-cell": {
						borderBottom: "none",
					},
					"& .name-column--cell": {
						color: colors.greenAccent[300],
					},
					"& .MuiDataGrid-columnHeaders": {
						backgroundColor: colors.blueAccent[700],
						borderBottom: "none",
					},
					"& .MuiDataGrid-virtualScroller": {
						backgroundColor: colors.primary[400],
					},
					"& .MuiDataGrid-footerContainer": {
						borderTop: "none",
						backgroundColor: colors.blueAccent[700],
					},
					"& .MuiCheckbox-root": {
						color: `${colors.greenAccent[200]} !important`,
					},
				}}
			>
				<DataGrid rows={swaps} columns={columns} />
			</Box>
	);
};

export default CabinetSwapList;
