import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const MAPDATA_URL = "https://asaliserver.powerhive.com/api/v1/cabinets/mapstatus"

const initialState = {
    mapData:[],
    firstMapDataLoading: false,
    subseQuentMapDataLoading: false,
    mapDataError: null,
    userCoordinates: null
}

export const fetchMapData = createAsyncThunk("cabinets/mapdata", async(_, thunkApi)=>{
    try {
        const res = await fetch(MAPDATA_URL)
        
        if(res.status === 200){
            const data = await res.json()
            return data?.cabinetData
        }else {
            throw new Error("ERROR WHILE FETCHING CABINET MAP DATA:::", res)
        }


    } catch (error) {
        console.log("ERROR WHILE FETCHING CABINET MAP DATA:::",error)
        return thunkApi.rejectWithValue("Error loading map data")
    }
})

const mapDataSlice = createSlice({
    name:'mapdata',
    initialState,
    reducers:{
        clearMap: (state) =>{
            state.mapData = []
            state.mapDataLoading = false
            state.mapDataError = null
        },
        setUserCoordinates: (state, action)=>{
            state.userCoordinates = action.payload // pauload {lat, long}
        },
        clearUserCoordinates: (state) =>{
            state.userCoordinates = null
        }
    },
    extraReducers: (builder)=>{
        builder
            .addCase(fetchMapData.pending, (state)=>{
                // there is the first time its fetching data ...
                // .. and subsequent fetches, 
                if(state.mapData.length === 0){
                    // this is the first load
                    state.firstMapDataLoading =  true
                    state.subseQuentMapDataLoading = false
                    state.mapData = []
                    state.mapDataError = null
                }else {
                    state.firstMapDataLoading =  false
                    state.subseQuentMapDataLoading = true
                    state.mapDataError = null
                }
            })
            .addCase(fetchMapData.fulfilled, (state, action)=>{
                state.firstMapDataLoading =  false
                state.subseQuentMapDataLoading = false
                state.mapData = action.payload
                state.mapDataError = null
            })
            .addCase(fetchMapData.rejected, (state, action)=>{
                state.firstMapDataLoading =  false
                state.subseQuentMapDataLoading = false
                state.mapDataError = action.payload
            })
    }
})

export default mapDataSlice.reducer  
export const {clearMap, setUserCoordinates, clearUserCoordinates} = mapDataSlice.actions