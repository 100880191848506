import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AUTH_TOKEN_KEY } from "../config";
import { getAuthToken } from "../utils/tokenprovider";

import { BASE_URL } from "../config";
const BATTERY_TELEMETRY_URL = `${BASE_URL}/api/v1/batteries/latestbatterytelemetry`
const VITALS_URL = `${BASE_URL}/api/v1/batteries/batteryvital`


const initialState = {
    latestBatteryTelemetry:{
        data: null,
        loading: false,
        error:""
    }, 
    battvitals:{
        data: null,
        loading: false,
        error:""
    },

}

export const getLatestBatteryTelemetry = createAsyncThunk("batteries/batteryTelemetry", async(_, thunkApi) =>{
    
    const token = getAuthToken(AUTH_TOKEN_KEY)
    if(!token) throw new Error("Auth token not found")
    
    try {
        const res = await fetch(BATTERY_TELEMETRY_URL, {
            headers: {
                "authorization":`Bearer ${token}`
            }
        })
        if(res.status === 200){
            const data = await res.json()
            return data?.data 
        }else {
            throw new Error("ERROR WHILE FETCHING BATERY DATA:::", res)
        }
    } catch (error) {
        console.log("UNABLE TO FETCH BATTERY TELEMETRY DATA::", error)
        return thunkApi.rejectWithValue("Error loading battery telemetry data")
    }
})


export const fetchBatVitals = createAsyncThunk("batteries/fechvitals", async(macid, thunkApi) =>{

        const token = getAuthToken(AUTH_TOKEN_KEY)
        if(!token) throw new Error("Auth token not found")
        try {
            const res = await fetch(`${VITALS_URL}?mac=${macid}`, {
                headers: {
                    "authorization":`Bearer ${token}`
                }
            })
            if(res.status === 200){
                const data = await res.json()
                return data?.data 
            }else {
                throw new Error("ERROR WHILE FETCHING ALL BATTERIES:::", res)
            }
        } catch (error) {
            console.log("UNABLE TO FETCH BATTERY  DATA::", error)
            return thunkApi.rejectWithValue("Error loading battery data")
        }
})


const batteriesSlice = createSlice({
    name: "batteriesData", 
    initialState,
    reducers: {}, 
    extraReducers: (builder)=>{
        builder
            .addCase(getLatestBatteryTelemetry.pending, (state)=>{
                state.latestBatteryTelemetry.loading = true
                state.latestBatteryTelemetry.error = ""
            })
            .addCase(getLatestBatteryTelemetry.fulfilled, (state, action)=>{
                state.latestBatteryTelemetry.loading = false
                state.latestBatteryTelemetry.data = {
                    raw: action.payload, 
                    activeBatteryCount: action.payload.filter(x => x.status === "Active").length, 
                    inactiveBatteryCount: (action.payload.length - action.payload.filter(x => x.status === "Active").length)
                }
                state.latestBatteryTelemetry.error = ""
            })
            .addCase(getLatestBatteryTelemetry.rejected, (state, action)=>{
                state.latestBatteryTelemetry.loading = false
                state.latestBatteryTelemetry.error = action.payloadpending
            })
            .addCase(fetchBatVitals.pending, (state)=>{
                state.battvitals.loading = true
                state.battvitals.error = ""
            })
            .addCase(fetchBatVitals.fulfilled, (state, action)=>{
                state.battvitals.loading = false
                state.battvitals.data = {
                    raw: action.payload
                }
                state.battvitals.error = ""
            })
            .addCase(fetchBatVitals.rejected, (state, action)=>{
                state.battvitals.loading = false
                state.battvitals.error = action.payloadpending
            })
    }
})

export const BatteriesApi = createApi({
    reducerPath:"batteriesApi", 
    baseQuery: fetchBaseQuery({
        baseUrl:BASE_URL,
        prepareHeaders :(headers) =>{
            const token = getAuthToken()

            if(token){
                headers.set("authorization", `Bearer ${token}`)
            }

            return headers
        }
    }),
    tagTypes:["Batteries", "Didpatched"],
    endpoints:(builder)=>({
        getBatteries: builder.query({
            query:({page, pagesize, batterypoolid})=>({
                method:"GET",
                url:"/api/v1/batteries",
                params:{page: (page+1), limit:pagesize, batterypool:batterypoolid}
            }), 
            providesTags:["Batteries"],
        }), 
        getLastDispatched: builder.query({
            query:({batteryid})=>({
               method:"GET", 
               url:"/api/v1/swaps/mostrecentdispatch", 
               params:{batteryid}
            }),
        }),
        getlastDeposited: builder.query({
            query:({batteryid})=>({
               method:"GET", 
               url:"/api/v1/swaps/mostrecentdeposited", 
               params:{batteryid}
            }),
        }), 
        getBatterySwapHistory: builder.query({
            query:({page, pagesize, batterypoolid})=>({
               method:"GET", 
               url:"/api/v1/swaps", 
               params:{bathist:batterypoolid, page:(page +1), limit:pagesize}
            }),
        }), 
    })
})


export const {
	useGetBatteriesQuery,
	useGetLastDispatchedQuery,
	useGetlastDepositedQuery,
    useGetBatterySwapHistoryQuery
} = BatteriesApi;

export default batteriesSlice.reducer