/**
 * This file sets up theme and color mode (light/dark) management using Material UI and custom context.
 * 
 * The setup consists of three main parts:
 * 
 * 1. **Defining the Theme Settings (`themeSettings`)**:
 *    - The `themeSettings` function dynamically sets colors based on the `mode` parameter (either "light" or "dark").
 *    - Inside this function, `tokens(mode)` provides color values according to the current mode.
 *    - The returned theme object configures:
 *        - `palette` colors based on `mode` for primary, secondary, neutral, and background colors.
 *        - `typography` settings for consistent font styling across components.
 * 
 * 2. **Creating Color Mode Context and Hook (`ColorModeContext` and `useMode`)**:
 *    - `ColorModeContext` is initialized with a `toggleColorMode` function that switches between light and dark modes.
 *    -  the initialization happens in layout.js in this file it has an empty function as a placeholder 
 *    - `useMode` is a custom hook that:
 *      - Manages the `mode` state (default: "dark") and provides `toggleColorMode` to switch modes.
 *      - Uses `useMemo` to create a new Material UI theme object each time the mode changes.
 *      - Returns `[theme, colorMode]`, which are the theme instance and color mode controller, respectively.
 * 
 * 3. **Providing Context in the Layout Component (`Layout.js`)**:
 *    - `Layout` wraps the app with `ColorModeContext.Provider` and `ThemeProvider`, making theme and color mode
 *      accessible across the component tree.
 *    - Usage:
 *       - `const [theme, colorMode] = useMode();` initializes `theme` and `colorMode`.
 *       - `<ColorModeContext.Provider value={colorMode}>`: Passes `colorMode` (including `toggleColorMode`) to children.
 *       - `<ThemeProvider theme={theme}>`: Wraps the app in `ThemeProvider`, applying the theme.
 *       - `CssBaseline` resets CSS to work seamlessly with Material UI.
 * 
 * 4. **Using Theme and Color Mode in Components (`Topbar.js`)**:
 *    - `Topbar` demonstrates how components can access and use the theme and color mode:
 *       - `const theme = useTheme();`: Retrieves the current Material UI theme (from `ThemeProvider`).
 *       - `const colorMode = useContext(ColorModeContext);`: Accesses `toggleColorMode`.
 *       - Example: `<IconButton onClick={colorMode.toggleColorMode}>` toggles between light and dark themes.
 *       - Colors can be dynamically accessed using `tokens(theme.palette.mode)`, applying colors based on the active mode.
 * 
 * **Summary**:
 * - `ColorModeContext` and `useMode` facilitate mode switching across the app.
 * - The `theme` instance from `useMode` updates dynamically, providing consistent theming via `ThemeProvider`.
 * - Components like `Topbar` can access and control color mode directly, ensuring a responsive UI theme.
 * 
 * Also by exporting tokens we can get the current pallete in any component simply by passing it the current mode from the context, like this
 *   const theme = useTheme()
    const colors = tokens(theme.palette.mode)
 */


import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

// color design tokens export
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        grey: {
          100: "#e0e0e0",
          200: "#c2c2c2",
          300: "#a3a3a3",
          400: "#858585",
          500: "#666666",
          600: "#525252",
          700: "#3d3d3d",
          800: "#292929",
          900: "#141414",
        },
        primary: {
          100: "#d0d1d5",
          200: "#a1a4ab",
          300: "#727681",
          400: "#1F2A40",
          500: "#141b2d",
          600: "#101624",
          700: "#0c101b",
          800: "#080b12",
          900: "#040509",
        },
        greenAccent: {
          100: "#fce1cd",
          200: "#f9c39b",
          300: "#f6a66a",
          400: "#f38838",
          500: "#F7A33A",  // Middle color
          600: "#c57f2e",
          700: "#946024",
          800: "#63401a",
          900: "#312010"
        },
        redAccent: {
          100: "#f8dcdb",
          200: "#f1b9b7",
          300: "#e99592",
          400: "#e2726e",
          500: "#db4f4a",
          600: "#af3f3b",
          700: "#832f2c",
          800: "#58201e",
          900: "#2c100f",
        },
        blueAccent: {
          100: "#e1e2fe",
          200: "#c3c6fd",
          300: "#a4a9fc",
          400: "#868dfb",
          500: "#6870fa",
          600: "#535ac8",
          700: "#3e4396",
          800: "#2a2d64",
          900: "#151632",
        },
      }
    : {
        grey: {
          100: "#141414",
          200: "#292929",
          300: "#3d3d3d",
          400: "#525252",
          500: "#666666",
          600: "#858585",
          700: "#a3a3a3",
          800: "#c2c2c2",
          900: "#e0e0e0",
        },
        primary: {
          100: "#040509",
          200: "#080b12",
          300: "#0c101b",
          400: "#f2f0f0", 
          500: "#141b2d",
          600: "#1F2A40",
          700: "#727681",
          800: "#a1a4ab",
          900: "#d0d1d5",
        },
        greenAccent: {
          100: "#312010",
          200: "#63401a",
          300: "#946024",
          400: "#c57f2e",
          500: "#F7A33A",  // Middle color remains the same
          600: "#f38838",
          700: "#f6a66a",
          800: "#f9c39b",
          900: "#fce1cd"
        },
        redAccent: {
          100: "#2c100f",
          200: "#58201e",
          300: "#832f2c",
          400: "#af3f3b",
          500: "#db4f4a",
          600: "#e2726e",
          700: "#e99592",
          800: "#f1b9b7",
          900: "#f8dcdb",
        },
        blueAccent: {
          100: "#151632",
          200: "#2a2d64",
          300: "#3e4396",
          400: "#535ac8",
          500: "#6870fa",
          600: "#868dfb",
          700: "#a4a9fc",
          800: "#c3c6fd",
          900: "#e1e2fe",
        },
      }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              main: colors.primary[500],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.primary[500],
            },
          }
        : {
            // palette values for light mode
            primary: {
              main: colors.primary[100],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: "#fcfcfc",
            },
          }),
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

// this creates a contect and lists togglecolomode which is initiated with an object that has an empty function
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});


// this is just a hook that returns a  theme boolean and a materual ui theme
export const useMode = () => {
  const [mode, setMode] = useState("dark");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
