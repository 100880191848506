import React from 'react'
import ProgressCircle from './ProgressCircle'
import  {Box, Typography, useTheme} from "@mui/material"
import { tokens } from "../theme";

const BatteryBox = ({batteryId, chargingprogress, temperature, door}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
  
    return (
      <Box width="100%" m="0 30px" onClick={()=>console.log("I have been clicked")} sx={{
        cursor:"pointer"
      }}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            {/* <BatteryChargingFullIcon /> */}
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ color: colors.grey[100] }}
            >
              Id: {batteryId}
            </Typography>
          </Box>
          <Box>
            <ProgressCircle progress={chargingprogress} />
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" mt="10px">
          <Typography variant="h5" sx={{ color: colors.greenAccent[500] }}>
            Battery Charge
          </Typography>
          <Typography
            variant="h5"
            fontStyle="italic"
            sx={{ color: colors.greenAccent[600] }}
          >
            {Math.floor((chargingprogress * 100)*100)/ 100}%
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" mt="2px">
          <Typography variant="h5" sx={{ color: colors.greenAccent[500] }}>
            Temperature
          </Typography>
          <Typography
            variant="h5"
            fontStyle="italic"
            sx={{ color: colors.greenAccent[600] }}
          >
            {temperature}C
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" mt="2px">
          <Typography variant="h5" sx={{ color: colors.greenAccent[500] }}>
            Door Number
          </Typography>
          <Typography
            variant="h5"
            fontStyle="italic"
            sx={{ color: colors.greenAccent[600] }}
          >
            {door}
          </Typography>
        </Box>
      </Box>
    );
}

export default BatteryBox