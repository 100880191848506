import { Box, useTheme } from "@mui/material";
import React from 'react';
import BatteryBox from '../../../components/BatteryBox';
import { tokens } from '../../../theme';

const BatteryDisplay = ({batteries, loading=false}) => {
    const theme = useTheme()
    const colors = tokens(theme.palette.mode);

  return (
    <Box
        display={"grid"}
        gridTemplateColumns={"repeat(12, 1fr)"}
        gridAutoRows="140px"
        gap="20px"
        mb={"20px"}
    >
        {batteries?.soc?.map((id, index)=>{
            const render = (
				<Box
					gridColumn="span 3"
					backgroundColor={colors.primary[400]}
					display="flex"
					alignItems="center"
					justifyContent="center"
                    key={`${index}${batteries?.canId[index]}`}
				>
                    {id 
                        ? (
                            <BatteryBox
                                batteryId={batteries?.canId[index] || "Id not available"}
                                chargingprogress={(id / 100 )}
                                temperature={batteries?.temperature[index]}
                                door = {index + 1}
                            />
                        )
                        :(
                            <Box>Empty Slot in Cabinet</Box>
                        )
                    }

				</Box>
			);

            return render
        })}
    </Box>
  )
}

export default BatteryDisplay