import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import MapMarker from "../../components/MapComponents/MapMarker";
import { useDispatch, useSelector } from "react-redux";
import { fetchMapData, setUserCoordinates } from "../../state_management/cabinetMapData";
import SquareSpinner from "../../components/LoaderComponents/SquareSpinner";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {MyLocation} from "@mui/icons-material"
import CompanyLogo from "../../assets/images/phivelogo.avif"


const CabinetMap = () => {

    const dispatch = useDispatch()
    const [displayInfo, setDisplayInfo] = useState(null)
    const {mapData,firstMapDataLoading,  mapDataError} = useSelector(state => state.mapinfo)    
    const [latlong, setLatLong] = useState([-1.286389, 36.817223])
    const [zoom, setZoom] = useState(12); 

    const transformMapData = (data)=>{
        return data.map((item, index)=>{
            return {
                position: [Number(item?.latitude), Number(item?.longitude)],
                label:item?.display_name,
                active: item?.cabinet_data?.device_status === 0 ? true : false,
                stateOfCharge: item?.cabinet_data?.soc || []
            }
        })
    }


    function userGrantedLocationPermission(position){
        const coordinates = position.coords
        const lat = coordinates.latitude
        const long = coordinates.longitude

        dispatch(setUserCoordinates({lat, long}))
        setLatLong([lat, long])
        setZoom(16)
        setDisplayInfo([...transformMapData(mapData), {position:[lat, long],label:"you", userCurrentLocation: true, active:false, stateOfCharge:[]}])
    }

    function getUserLocationError(err){
        console.log("Problem getting user location::", err)
        toast.error("Could not get your location, please enable location access on your phone/pc")
    }


    function getMyLocation(){
        if(navigator.geolocation){
            navigator.geolocation.getCurrentPosition(
                userGrantedLocationPermission,
                getUserLocationError,
                {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0,
                }
            )
        }else{
            console.log("Browser does not support geolocation")
        }
    }



    useEffect(()=>{
        if(mapData.length  > 0){
            setDisplayInfo(transformMapData(mapData))
        }
    },[mapData])

    useEffect(()=>{
        dispatch(fetchMapData())

        const intervalId = setInterval(()=>{
            dispatch(fetchMapData());
        }, 180000)


        return () => clearInterval(intervalId)
    },[dispatch])

    useEffect(()=>{
        if(mapDataError){
            toast.error(mapDataError)
        }
    },[mapDataError])

    if(firstMapDataLoading || !displayInfo){
        return (
            <div style={{minHeight:"90vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <SquareSpinner height="100" width="100" color="#F7A33A"/>
            </div>
        )
    }

    const ChangeView = ({ center, zoom }) => {
        const map = useMap();
        map.setView(center, zoom);  // Update the map view to the new center
        return null;
    };

    
      return (
        <div style={{
            marginLeft:"-10px",
            marginTop:"-10px"
        }}>
          <MapContainer center={latlong} zoom={zoom}>
            <ChangeView center={latlong} zoom={zoom}/>
            <TileLayer url ="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <MarkerClusterGroup >
                {displayInfo.map((marker, index) =>{
                    return (<MapMarker key={index} mapdata={marker}/> )
                })}
            </MarkerClusterGroup>
          </MapContainer>
          <button style={{
                position:"fixed",
                bottom: 20,
                right: 30, 
                zIndex:1000,
                borderRadius: "50%",
                border:"none",
                padding:"10px",
                color:"white",
                backgroundColor:"#3E4396",
                cursor:"pointer",
                display:"flex",
                justifyContent:"center",
                alignItems:"center",
                
            }}
                onClick={getMyLocation}
          >
            <MyLocation />
          </button>
          <span
            style={{
                position:"fixed",
                top: 20,
                right: 30, 
                zIndex:1000,
            }}
          >
            <img src={CompanyLogo} alt="company logo" width={150} height={50}/>
          </span>
        </div>
      );
}

export default CabinetMap